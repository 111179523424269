import "./styles/App.css";
import "./assets/css/bootstrap.css";
import "./assets/css/animate.min.css";
import "./assets/css/swiper.min.css";
import "./assets/css/color5.css";
import "./styles/devices.min.css";
import "./styles/Custom.css";
import "./styles/Timeline.css";
import "./styles/Animation.css";
import "./styles/xoneToggler.css";
import Particles from 'react-tsparticles';
import { useMemo } from 'react';
import * as anchor from '@project-serum/anchor';
import Home from './Home';
import { DEFAULT_TIMEOUT } from './connection';
import { clusterApiUrl } from '@solana/web3.js';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletWallet,
  getSolletExtensionWallet,
} from '@solana/wallet-adapter-wallets';

import {
  ConnectionProvider,
  WalletProvider,
} from '@solana/wallet-adapter-react';
import { WalletDialogProvider } from '@solana/wallet-adapter-material-ui';

import { ThemeProvider, createTheme } from '@material-ui/core';

const theme = createTheme({
  palette: {
    type: 'dark',
  },
});

const getCandyMachineId = (): anchor.web3.PublicKey | undefined => {
  try {
    const candyMachineId = new anchor.web3.PublicKey(
      process.env.REACT_APP_CANDY_MACHINE_ID!,
    );

    return candyMachineId;
  } catch (e) {
    console.log('Failed to construct CandyMachineId', e);
    return undefined;
  }
};

const candyMachineId = getCandyMachineId();
const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;
const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(
  rpcHost ? rpcHost : anchor.web3.clusterApiUrl('devnet'),
);

const App = () => {

  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSolflareWallet(),
      getSlopeWallet(),
      getSolletWallet({ network }),
      getSolletExtensionWallet({ network }),
    ],
    [],
  );

  return (
    <div id="home" className="mb-0 pb-0">

    {/* CL Loader ::  
    <div className="loader-wrapper">
          <div id="loader">
            <div id="shadow"></div>
            <div id="box"></div>
          </div>
        </div>
    */}
    
  <header className="card text-center align-items-center rounded-0 px-1 px-md-2" id="hero-minter">
    <div className="mb-hero-bg"></div>
    <nav className="navbar navbar-expand-lg theme-nav fixed-top">
      <div className="container">
        <a className="navbar-brand d-flex justify-content-center align-items-center noselect" href="/">
          <img className="cl-nav-logo noselect" src="images/logo.svg" alt="Metaballix logo" />
          <span className="logo-title mx-2">METABALLIX</span>
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mainmenu" aria-controls="mainmenu" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"><i className="fa fa-align-justify" aria-hidden="true"></i></span></button>
        <div className="navbar-collapse collapse" id="mainmenu">
          <ul className="navbar-nav ms-auto" id="mymenu">
            <li className="nav-item"><a className="nav-link" href="/#home">Home</a></li>
            <li className="nav-item"><a className="nav-link" href="/#nft">NFT<span className="subtext">s</span></a></li>
            <li className="nav-item dropdown">
              <a id="xonemenuLink" className="nav-link dropdown-toggle" href="/#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Microverses</a>
              <ul className="dropdown-menu shadow" aria-labelledby="xonemenuLink" style={{ fontSize: "0.8rem" }}>
                <li className="nav-item"><a className="nav-link" href="/#xone">XONES</a></li>
                <li className="nav-item"><a className="nav-link" href="/#xonerator">Xonerator</a></li>
                <li className="nav-item"><a className="nav-link" href="/#solus">Solus Demo</a></li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a id="xonemenuLink" className="nav-link dropdown-toggle" href="/#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Metaverse</a>
              <ul className="dropdown-menu shadow" aria-labelledby="xonemenuLink" style={{ fontSize: "0.8rem" }}>
                <li className="nav-item"><a className="nav-link" href="/#plasmaverse">PLASMAVERSE</a></li>
                <li className="nav-item"><a className="nav-link" href="/#techstack">Technology</a></li>
                <li className="nav-item"><a className="nav-link" href="/#showcase">Showcase</a></li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a id="tokenmenuLink" className="nav-link dropdown-toggle" href="/#tokenomics" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Tokenomics</a>
              <ul className="dropdown-menu shadow" aria-labelledby="tokenmenuLink" style={{ fontSize: "0.8rem" }}>
                <li className="nav-item"><a className="nav-link" href="/#mball-token">$MBALL</a></li>
                <li className="nav-item"><a className="nav-link" href="/#xone-token">$XONE</a></li>
                <li className="nav-item"><a className="nav-link" href="/#plasma-token">$PLASMA</a></li>
              </ul>
            </li>
            <li className="nav-item"><a className="nav-link" href="/#team">Team</a></li>
            <li className="nav-item"><a className="nav-link" href="/#faq">FAQ<span className="subtext">s</span></a></li>
          </ul>
        </div>
      </div>
    </nav>
    <div className="container text-center align-items-center p-1 my-auto">
      {/* <div className="bg-overlay-2"></div> */}
      <div className="row align-items-center my-auto mb-0">

        <div className="col-12 col-lg-6 mt-2 mt-md-3 mt-lg-5 pb-1 animatable fadeInRight">
          <h3 className="hero-text text-secondary-color noselect">Welcome to the Plasmaverse</h3>
            <h1 className="hero-title pb-3 noselect"><span className="h1-engage">ENGAGE. </span><span className="h1-expand">EARN. </span><span className="h1-earn">EXPAND. </span></h1>
          <p className="hero-desc pb-0 pl-1 px-2 noselect">
          Mint a <b style={{ filter: "drop-shadow(0 0 0.38rem rgb(202, 51, 182))" }}>Metaball avatar</b> to claim your Solana-powered <b style={{ filter: "drop-shadow(0 0 0.38rem rgb(202, 51, 182))" }}>monetizable microverses</b>! <a href="/#nft"><b className="badge hero-xone-badge noselect">LEARN MORE</b></a>
            </p>
        </div>
    
        {/* METABALL MINTER v1.1 (cmv2) - Hero Dialog Box */}
        <div className="col-12 col-lg-6 px-0 noselect animatable fadeInLeft">
        <ThemeProvider theme={theme}>
          <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
              <WalletDialogProvider>
                <Home
                  candyMachineId={candyMachineId}
                  connection={connection}
                  txTimeout={DEFAULT_TIMEOUT}
                  rpcHost={rpcHost}
                />
              </WalletDialogProvider>
            </WalletProvider>
          </ConnectionProvider>
        </ThemeProvider>
        </div>
        {/* End of METABALL MINTER Dialog Box */}
    
      </div>
    </div>
  </header>
    
    {/* START of SECTION :: FEATURES */}
    <section id="nft" className="theme-bg feature py-0 my-0 text-center bxs-purp1 mb-0">
    
    {/* NFTs Card with mBall logo */}
    <div className="mbp-slider-card mt-0 pt-2 pb-5 bxs-purp1">
    
    <div className="row px-2 mx-auto mb-3 p-0">
      <div className="mbp-photos custom-scrollbar-css" data-tilt="" data-tilt-perspective="100" data-tilt-speed="400" data-tilt-max="1.8">
        <div className="mbp-photos-inner mx-0 mx-md-1 noselect">
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img3" src="images/nfts/9.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img4" src="images/nfts/13.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img5" src="images/nfts/15.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img2" src="images/nfts/8.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img6" src="images/nfts/19.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img7" src="images/nfts/20.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img8" src="images/nfts/25.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img9" src="images/nfts/110.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img10" src="images/nfts/35.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img26" src="images/nfts/148.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>   
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img11" src="images/nfts/39.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img12" src="images/nfts/40.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img13" src="images/nfts/43.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img14" src="images/nfts/47.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img15" src="images/nfts/75.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img16" src="images/nfts/96.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img17" src="images/nfts/108.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img18" src="images/nfts/114.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img19" src="images/nfts/130.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img20" src="images/nfts/132.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img21" src="images/nfts/134.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img22" src="images/nfts/147.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img23" src="images/nfts/145.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img24" src="images/nfts/155.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img25" src="images/nfts/146.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img27" src="images/nfts/162.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/163.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/35.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/52.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/57.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/60.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/68.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/70.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/89.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/99.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/100.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/102.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/103.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/104.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/105.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/106.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/107.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/109.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/111.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/112.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/113.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/115.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/116.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/117.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/118.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/119.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/120.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/121.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/122.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/124.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/125.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/126.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/127.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/128.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/129.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/131.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/133.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/135.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/136.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/137.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/138.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/139.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/140.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/141.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/142.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/143.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/144.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/146.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/148.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/149.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/150.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/151.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/152.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/153.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/154.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/155.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/156.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/157.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/158.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/159.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/160.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/161.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/162.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/166.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/167.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/168.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/169.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/170.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/171.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/172.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/173.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/174.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/175.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/176.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/177.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/178.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/179.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/180.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/181.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/182.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        <a className="mbp-nft-box" href="/#"><img className="mbp-nft-img img28" src="images/nfts/185.png" alt="Metaball NFT Variant (Sample)" data-tilt="" /></a>
        </div>
      </div>
    </div>
    
    <div className="mbp-logo animatable bounceIn noselect">
    <img src="https://cdn.glitch.global/37011ed6-4b84-44e8-8f53-630683dd483b/xone-body.gif?v=1649715485317" alt="metaball NFTs body variants" />
    </div>
    {/* <div className="stage d-block mx-auto" style={{ marginLeft: 0, marginTop: "-65px" }}><figure className="4x1 ball bubble"></figure></div> */}
    
    <div className="row justify-content-center mt-4 mx-2">
      <div className="col-10 col-lg-8 my-2 content">
        <h1 className="display-5 fw-bold" style={{ letterSpacing: "0.15rem" }}>METABALLS</h1>
        <h3 className="sub-header fw-bold fs-2"><span style={{color: "rgb(182, 38, 182)"}}>UNLOCK. </span><span style={{color: "rgb(101, 38, 182)"}}>MINT. </span><span style={{color: "rgb(38, 137, 182)"}}>TRADE.</span></h3>
        <p className="py-3 description lead fs-3">Metaballs are avatars that reside in the Plasmaverse. Your NFT will be tied to on-chain records such as estate metadata, $PLASMA and asset holdings, and visitor traffic.</p>
      </div>
    </div>
    
    <div className="row box mx-1">
        <div className="col-6 col-md-4 py-2">
        <div className="box media">
          <h2 className="gradient-txt-green fw-bold fs-2 pb-0">22,000</h2>
          <h4 className="text-white">NFTs, 11k per series</h4>
        </div>
        </div>
        <div className="col-6 col-md-4 py-2">
        <div className="box followers">
          <h2 className="gradient-txt-green fw-bold fs-2 pb-0">19,326,120</h2>
          <h4 className="text-white">Unlockable Variants</h4>
        </div>
        </div>
        <div className="col-6 col-md-4 py-2">
        <div className="box following">
          <h2 className="gradient-txt-green fw-bold fs-2 pb-0">38,652,240</h2>
          <h4 className="text-white">Xone Estates</h4>
        </div>
        </div>
{/*         
<div className="col-12 mb-2">
  <div className="profile-card-social">
    <a href="https://twitter.com/metaballix" className="profile-card-social__item twitter" target="_blank" rel="noreferrer">
      <span className="icon-font">
          <svg className="icon"><use xlinkHref="#icon-twitter"></use></svg>
      </span>
    </a>

    <a href="https://github.com/metaballix" className="profile-card-social__item github" target="_blank" rel="noreferrer">
      <span className="icon-font">
          <svg className="icon"><use xlinkHref="#icon-github"></use></svg>
      </span>
    </a>

    <a href="https://tg.me/metaballix" className="profile-card-social__item codepen" target="_blank" rel="noreferrer">
      <span className="icon-font">
          <svg className="icon"><use xlinkHref="#icon-codepen"></use></svg>
      </span>
    </a>

    <a href="https://t.co/bbFbL81076" className="profile-card-social__item link" target="_blank" rel="noreferrer">
      <span className="icon-font">
          <svg className="icon"><use xlinkHref="#icon-link"></use></svg>
      </span>
    </a>
  </div>
</div>
*/}
    </div>
    
    </div>
    </section>
    {/* End of FEATURES section */}
    
    {/* Start of XONES Section */}
    <section id="features" className="container-fluid d-flex w-100 h-auto px-0 pb-5 pt-0 my-0 mx-auto flex-column">
    <div className="b-divider"></div>

    {/* Start of HONE YOUR XONE */}
    <div id="xone" className="container pt-5 pb-1 pb-md-2 my-3 mb-4 text-center mx-auto px-1">
    <div id="xone-inner" className="row mb-2 p">
        <div className="col col-lg-7 mb-5">
          <div className="mbp-wrapper-2">
            
            <div className="profile-card js-profile-card shadow">
              <div className="profile-card__img noselect">
              <div className="stage d-block mx-auto animatable bounceIn" style={{ marginLeft: 0 }}><figure className="4x1 ball bubble"></figure></div>
                {/* <img src="https://cdn.glitch.global/37011ed6-4b84-44e8-8f53-630683dd483b/xone-body.gif?v=1649715485317" alt="xone body variants" /> */}
              </div>

        <div className="profile-card__cnt js-profile-cnt">
        <h1 className="display-5 fw-bold gradient-txt-green">HONE YOUR XONES</h1>
          <div className="profile-card__txt fs-4 px-1 px-md-2">Your Metaball sphere is comprised of halves, or two hemispheres. Each hemisphere is a distinct semi-autonomous virtual estate called a Xone.</div>
          <div className="row">
            <div className="col-12 mb-2">
            <h4 className="text-secondary fw-bold w-100">Your Metaball NFT holds</h4>
              <h3 className="gradient-txt-green fw-bold fs-2">2 $XONE + 1K $PLASMA<sub className="gradient-txt-green">min.</sub></h3>
            </div>
          </div>
          <div className="profile-card__txt fs-4 px-1 px-md-2">Your $XONE tokens will allow you to claim your digital estates when the platform launches. You will then use $PLASMA to improve your Xone for your audience.</div>
    
        <div className="d-flex justify-content-evenly justify-content-xs-between align-items-center g-1">
            <a href="/#xone-token"><button className="profile-card__button button--blue js-message-btn">$XONE</button></a>
            <a href="/#plasma-token"><button className="profile-card__button button--orange">$PLASMA</button></a>
          </div>
        </div>
    {/* 
    <div className="row">
      <div className="profile-card-loc">
            <span className="profile-card-loc__icon">
              <svg className="icon"><use xlinkHref="#icon-location"></use></svg>
            </span>
            <span className="profile-card-loc__txt">
              Istanbul, Turkey
            </span>
      </div>
    </div>
    */}

    <div className="profile-card-message js-message">
      <form className="profile-card-form">
        <div className="profile-card-form__container">
          <textarea placeholder="Say something..."></textarea>
        </div>
    
        <div className="profile-card-form__bottom">
          <button className="profile-card__button button--blue js-message-close">
            Send
          </button>
    
          <button className="profile-card__button button--gray js-message-close">
            Cancel
          </button>
        </div>
      </form>
    
      <div className="profile-card__overlay js-message-close"></div>
    </div>
    
    </div>
    
          </div>
        </div>
    
    <div className="col col-lg-5">
    {/* Start of Feature Squares Grid 4x2 */}
    <div className="xone-feat">
        <div className="container">
            <div className="row h-100 xone-feat-wrapper">

            <div className="col-6 animatable bounceIn">
              <div className="card card-shadow border-0 mb-4">
                  <div className="card-hover py-4 text-center d-block rounded"> 
                    <div className="xone-feat-icon" style={{ inset: "-50px auto auto -92px" }}>
                    <svg className="xone-feat-svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="-32 -32 128 128" width="500px" height="500px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve">
                    <g>
                      <path d="M29.3,22.8l-3.9-4.7C24.8,17.4,24,17,23.1,17H8.9c-0.9,0-1.7,0.4-2.3,1.1l-3.9,4.7C2.2,23.3,2,24,2,24.7V27
                        c0,1.7,1.3,3,3,3h22c1.7,0,3-1.3,3-3v-2.3C30,24,29.8,23.3,29.3,22.8z M8.2,19.4C8.4,19.1,8.6,19,8.9,19h14.1
                        c0.3,0,0.6,0.1,0.8,0.4l3,3.6H5.1L8.2,19.4z"/>
                      <path d="M12.4,11.6C13,14.1,14.2,16,16,16s3-1.9,3.6-4.4C22.1,11,24,9.8,24,8s-1.9-3-4.4-3.6C19,1.9,17.8,0,16,0s-3,1.9-3.6,4.4
                        C9.9,5,8,6.2,8,8S9.9,11,12.4,11.6z M16,14c-0.4,0-1-0.8-1.4-2.1c0.5,0,0.9,0.1,1.4,0.1s0.9,0,1.4-0.1C17,13.2,16.4,14,16,14z
                        M14,8c0-0.7,0.1-1.3,0.1-1.9C14.7,6.1,15.3,6,16,6s1.3,0.1,1.9,0.1C17.9,6.7,18,7.3,18,8s-0.1,1.3-0.1,1.9C17.3,9.9,16.7,10,16,10
                        s-1.3-0.1-1.9-0.1C14.1,9.3,14,8.7,14,8z M22,8c0,0.4-0.8,1-2.1,1.4C20,8.9,20,8.5,20,8s0-0.9-0.1-1.4C21.2,7,22,7.6,22,8z M16,2
                        c0.4,0,1,0.8,1.4,2.1C16.9,4,16.5,4,16,4s-0.9,0-1.4,0.1C15,2.8,15.6,2,16,2z M12.1,6.6C12,7.1,12,7.5,12,8s0,0.9,0.1,1.4
                        C10.8,9,10,8.4,10,8S10.8,7,12.1,6.6z"/>
                    </g>
                    </svg>
                    </div>
                    <span className="bg-success-grad fw-bold">XR</span>
                    <h6 className="ser-title">MICROVERSES</h6>
                  </div>
              </div>
            </div>

            <div className="col-6 animatable bounceIn">
                <div className="card card-shadow border-0 mb-4">
                    <div className="card-hover py-4 text-center d-block rounded">
                      <div className="xone-feat-icon" style={{ inset: "-153px auto auto -192px", transform: "rotate(0deg)" }}>
                        <svg className="xone-feat-svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="-32 -32 96 96" height="450px" width="480px" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve">
                        <path d="M27,11c0-2-2.2-3.6-5.6-4.4C20.6,3.2,19,1,17,1s-3.6,2.2-4.4,5.6C9.2,7.4,7,9,7,11c0,2,2.2,3.6,5.6,4.4
                        c0.3,1.2,0.6,2.2,1.1,3.1c0.3,0.5,0.9,0.7,1.4,0.4c0.5-0.3,0.7-0.9,0.4-1.4c-0.3-0.5-0.5-1.1-0.7-1.8c0.7,0.1,1.5,0.1,2.2,0.1
                        c0.8,0,1.5,0,2.2-0.1c-0.3,1-0.7,1.9-1.1,2.5c-0.3,0.4-0.2,1.1,0.2,1.4c0.2,0.1,0.4,0.2,0.6,0.2c0.3,0,0.6-0.1,0.8-0.4
                        c0.7-1,1.3-2.4,1.7-4C24.8,14.6,27,13,27,11z M17,3c0.7,0,1.6,1.2,2.2,3.3c-0.7-0.1-1.5-0.1-2.2-0.1s-1.5,0-2.2,0.1
                        C15.4,4.2,16.3,3,17,3z M9,11c0-0.7,1.2-1.6,3.3-2.2c-0.1,0.7-0.1,1.5-0.1,2.2c0,0.8,0,1.5,0.1,2.2C10.2,12.6,9,11.7,9,11z M17,13.9
                        c-1,0-1.9-0.1-2.7-0.2c-0.1-0.8-0.2-1.7-0.2-2.7c0-1,0.1-1.9,0.2-2.7C15.1,8.2,16,8.1,17,8.1s1.9,0.1,2.7,0.2
                        c0.1,0.8,0.2,1.7,0.2,2.7c0,0.9-0.1,1.8-0.2,2.7C18.9,13.8,18,13.9,17,13.9z M21.7,13.2c0.1-0.7,0.1-1.5,0.1-2.2
                        c0-0.8,0-1.5-0.1-2.2C23.8,9.4,25,10.3,25,11C25,11.7,23.8,12.6,21.7,13.2z"/>
                        <path d="M29.9,17.5C29.7,17.2,29.4,17,29,17c-2.2,0-4.3,1-5.6,2.8L22.5,21c-1.1,1.3-2.8,2-4.5,2h-3c-0.6,0-1-0.4-1-1s0.4-1,1-1h1.9
                        c1.6,0,3.1-1.3,3.1-2.9c0,0,0-0.1,0-0.1c0-0.5-0.5-1-1-1l-6.1,0c-3.6,0-6.5,1.6-8.1,4.2l-2.7,4.2c-0.2,0.3-0.2,0.7,0,1l3,5
                        c0.1,0.2,0.4,0.4,0.6,0.5c0.1,0,0.1,0,0.2,0c0.2,0,0.4-0.1,0.6-0.2c3.8-2.5,8.2-3.8,12.7-3.8c3.3,0,6.3-1.8,7.9-4.7l2.7-4.8
                        C30,18.2,30,17.8,29.9,17.5z"/>
                        </svg>
                      </div>
                      <span className="bg-success-grad fw-bold">C</span>
                      <h6 className="ser-title">COMPONENTS</h6>
                    </div>
                </div>
            </div>

            <div className="col-6 animatable bounceIn">
                <div className="card card-shadow border-0 mb-4">
                    <div className="card-hover py-4 text-center d-block rounded"> 
<div className="xone-feat-icon" style={{ inset: "-75px auto auto -35px" }}>
<svg className="xone-feat-svg" stroke="currentColor" fill="currentColor" strokeWidth="0" width="400px" height="350px" viewBox="-50 -50 200 200" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve">
<path d="M73.8,20H26.2c-3.4,0-6.2,2.8-6.2,6.2v47.6c0,3.4,2.8,6.2,6.2,6.2h47.6c3.4,0,6.2-2.8,6.2-6.2V26.2
	C80,22.8,77.2,20,73.8,20z M42.3,69.1c0,0.8-0.8,1.4-1.6,1.4H30.6h-0.2c-0.8,0-1.4-0.8-1.4-1.6V48.8c0-0.8,0.8-1.4,1.6-1.4H41
	c0.8,0,1.4,0.8,1.4,1.6V69.1z M70.7,69.3c0,0.8-0.8,1.4-1.6,1.4H49.9c-0.8,0-1.4-0.8-1.4-1.6V48.8c0-0.8,0.8-1.4,1.6-1.4h19.1h0.2
	c0.8,0,1.4,0.8,1.4,1.6V69.3z M70.9,39.9c0,0.8-0.8,1.4-1.6,1.4H30.6h-0.2c-0.8,0-1.4-0.8-1.4-1.6v-9c0-0.8,0.8-1.4,1.6-1.4h38.9
	c0.8,0,1.4,0.8,1.4,1.6V39.9z"/>
</svg>
</div>
                      <span className="bg-success-grad fw-bold">B</span>
                      <h6 className="ser-title">BOILERPLATES</h6>
                    </div>
                </div>
            </div>

            <div className="col-6 animatable bounceIn">
                <div className="card card-shadow border-0 mb-4">
                    <div className="card-hover py-4 text-center d-block rounded">
                      <div className="xone-feat-icon" style={{ inset: "-200px auto auto -184px" }}>
                        <svg className="xone-feat-svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="-24 -24 72 72" height="490" width="490" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" version="1.1">
                          <g><path fill="none" d="M0 0H24V24H0z"></path><path d="M17.363 11.045c1.404-1.393 3.68-1.393 5.084 0 1.404 1.394 1.404 3.654 0 5.047L17 21.5l-5.447-5.408c-1.404-1.393-1.404-3.653 0-5.047 1.404-1.393 3.68-1.393 5.084 0l.363.36.363-.36zm1.88-6.288c.94.943 1.503 2.118 1.689 3.338-1.333-.248-2.739-.01-3.932.713-2.15-1.303-4.994-1.03-6.856.818-2.131 2.115-2.19 5.515-.178 7.701l.178.185 2.421 2.404L11 21.485 2.52 12.993C.417 10.637.496 7.019 2.757 4.757c2.265-2.264 5.888-2.34 8.244-.228 2.349-2.109 5.979-2.039 8.242.228z"></path></g></svg>
                      </div>
                      <span className="bg-success-grad fw-bold">I</span>
                      <h6 className="ser-title">INTEGRATIONS</h6>
                    </div>
                </div>
            </div>

            <div className="col-6 animatable bounceIn">
                <div className="card card-shadow border-0 mb-4">
                    <div className="card-hover py-4 text-center d-block rounded"> 

<div className="xone-feat-icon" style={{ inset: "-74px auto auto -47px" }}>
<svg  className="xone-feat-svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="-32 -32 128 128" width="300px" height="300px" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" version="1.1">
<g>
	<path d="M48,60c-1.104,0-2-0.896-2-2v-9.172L43.172,46H34c-0.53,0-1.039-0.211-1.414-0.586l-10-10
		c-0.781-0.781-0.781-2.047,0-2.828c0.78-0.781,2.048-0.781,2.828,0L34.828,42h4.344L28.586,31.414
		c-0.781-0.781-0.781-2.047,0-2.828c0.78-0.781,2.048-0.781,2.828,0L38.828,36l9.173,0.001c0.53,0,1.039,0.211,1.414,0.586
		l9.999,9.999c0.781,0.781,0.781,2.047,0,2.828s-2.047,0.781-2.828,0l-9.413-9.413L42.829,40l16.585,16.586
		c0.781,0.781,0.781,2.047,0,2.828s-2.047,0.781-2.828,0L50,52.828V58C50,59.104,49.104,60,48,60z M40.001,32.001
		c-0.512,0-1.023-0.195-1.414-0.586l-6-6.001c-0.781-0.781-0.781-2.047,0-2.828c0.781-0.781,2.046-0.781,2.828,0l6.001,6.001
		c0.781,0.781,0.781,2.047,0,2.828C41.024,31.806,40.513,32.001,40.001,32.001z"/>
	<path d="M32.146,7.853c5.753,5.75,4.976,15.851-1.735,22.559c-6.708,6.711-16.808,7.488-22.559,1.735
		c-5.75-5.751-4.975-15.85,1.735-22.559C16.296,2.878,26.396,2.102,32.146,7.853z M27.542,27.541
		c2.595-2.596,4.166-5.859,4.422-9.19c0.235-3.052-0.689-5.786-2.602-7.699c-2.18-2.18-4.891-2.637-6.781-2.637
		c-3.591,0-7.275,1.625-10.108,4.459c-5.159,5.158-5.976,12.733-1.821,16.889c2.18,2.179,4.891,2.637,6.78,2.637
		C21.023,32,24.708,30.375,27.542,27.541z M18.934,18.934c0.626-0.626,1.004-1.412,1.066-2.214c0.056-0.736-0.166-1.395-0.627-1.855
		c-0.525-0.526-1.179-0.636-1.634-0.636c-0.865,0-1.753,0.391-2.437,1.074c-1.242,1.243-1.44,3.069-0.438,4.069
		c0.525,0.525,1.177,0.636,1.633,0.636C17.363,20.007,18.25,19.616,18.934,18.934z M50,58c0-1.104-0.896-2-2-2s-2,0.896-2,2
		s0.896,2,2,2S50,59.104,50,58z M60,58c0-1.104-0.896-2-2-2s-2,0.896-2,2s0.896,2,2,2S60,59.104,60,58z M60,48c0-1.104-0.896-2-2-2
		s-2,0.896-2,2s0.896,2,2,2S60,49.104,60,48z M42,30c0-1.104-0.896-2-2-2s-2,0.896-2,2s0.896,2,2,2S42,31.104,42,30z"/>
</g>
</svg>
</div>
                        <span className="bg-success-grad fw-bold">T</span>
                        <h6 className="ser-title">TOKENIZED</h6>
                    </div>
                </div>
            </div>

            <div className="col-6 animatable bounceIn">
                <div className="card card-shadow border-0 mb-4">
                    <div className="card-hover py-4 text-center d-block rounded"> 
                      <div className="xone-feat-icon" style={{ inset: "-25px auto auto -10px" }}>
                        <svg className="xone-feat-svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="-32 -32 128 128" width="256px" height="256px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve">
                        <g id="wallet">
                          <path className="st1" d="M15,24c-3.86,0-7,2.691-7,6v20c0,3.309,3.14,6,7,6h41V32H15c-1.598,0-3-0.935-3-2s1.402-2,3-2h5.25
                            c0,0,1-5,5.75-5s6,5,6,5h22v-4H15z"/>
                          <path className="st0" d="M42,4c-4.418,0-8,3.582-8,8s3.582,8,8,8c4.417,0,8-3.582,8-8S46.417,4,42,4z M42,16c-2.208,0-4-1.792-4-4
                            s1.792-4,4-4s4,1.792,4,4S44.208,16,42,16z"/>
                          <path className="st0" d="M26,20c-4.418,0-8,3.582-8,8h4c0-2.208,1.792-4,4-4s4,1.792,4,4h4C34,23.582,30.418,20,26,20z"/>
                        </g>
                        </svg>
                        </div>
                      <span className="bg-success-grad fw-bold">M</span>
                      <h6 className="ser-title">MONETIZABLE</h6>
                    </div>
                </div>
            </div>
                
            <div className="col-6 animatable bounceIn">
                <div className="card card-shadow border-0 mb-4">
                    <div className="card-hover py-4 text-center d-block rounded">
                      <div className="xone-feat-icon" style={{ inset: "-70px auto auto -137px" }}>
                      <svg className="xone-feat-svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="-48 -48 156 156" width="450px" height="450px" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve">
                        <g id="plasmacoin">
<path d="M38,54c0,3.313-2.687,6-6,6s-6-2.687-6-6s2.687-6,6-6S38,50.687,38,54z M32,4c-3.313,0-6,2.687-6,6
s2.687,6,6,6s6-2.687,6-6S35.313,4,32,4z M48,32c0,3.313,2.687,6,6,6s6-2.687,6-6s-2.687-6-6-6S48,28.687,48,32z M4,32
c0,3.313,2.687,6,6,6s6-2.687,6-6s-2.687-6-6-6S4,28.687,4,32z M43.313,43.313c-2.343,2.343-2.343,6.143,0,8.485
c2.344,2.343,6.143,2.343,8.485,0s2.343-6.143,0-8.485S45.657,40.971,43.313,43.313z M12.201,12.201
c-2.343,2.343-2.343,6.142,0,8.485c2.343,2.343,6.142,2.343,8.485,0c2.343-2.343,2.343-6.143,0-8.485
C18.343,9.858,14.544,9.858,12.201,12.201z M43.313,20.687c2.344,2.343,6.143,2.343,8.485,0c2.343-2.343,2.343-6.143,0-8.485
c-2.343-2.343-6.142-2.343-8.485,0C40.971,14.544,40.971,18.343,43.313,20.687z M12.201,51.799c2.343,2.343,6.142,2.343,8.485,0
c2.343-2.343,2.343-6.143,0-8.485c-2.343-2.343-6.143-2.343-8.485,0C9.858,45.656,9.858,49.456,12.201,51.799z"/>
{/* 	<path d="M32,20c-6.627,0-12,5.373-12,12c0,6.627,5.373,12,12,12c6.627,0,12-5.373,12-12C44,25.373,38.627,20,32,20z
  M40,33c0,0.553-0.447,1-1,1h-5v5c0,0.553-0.447,1-1,1h-2c-0.552,0-1-0.447-1-1v-5h-5c-0.552,0-1-0.447-1-1v-2c0-0.553,0.448-1,1-1
h5v-5c0-0.552,0.448-1,1-1h2c0.553,0,1,0.448,1,1v5h5c0.553,0,1,0.447,1,1V33z"/> */}
</g>
                      </svg>
                      </div>
                      <span className="bg-success-grad fw-bold">D</span>
                      <h6 className="ser-title">DECENTRALIZED</h6>
                    </div>
                </div>
            </div>
    
            <div className="col-6 animatable bounceIn">
                <div className="card card-shadow border-0 mb-4">
                    <div className="card-hover py-4 text-center d-block rounded"> 
                      <div className="xone-feat-icon" style={{ inset: "-203px auto auto -144px" }}>
                        <svg className="xone-feat-svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="-48 -48 128 128" height="380px" width="380px" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve">
                          <path d="M36.47 39.47C36.47 39.8678 36.628 40.2494 36.9093 40.5307C37.1906 40.812 37.5722 40.97 37.97 40.97C38.3678 40.97 38.7494 40.812 39.0307 40.5307C39.312 40.2494 39.47 39.8678 39.47 39.47V26C39.4703 25.6995 39.3797 25.406 39.2101 25.158C39.0406 24.9099 38.8 24.7189 38.52 24.61C38.3458 24.5371 38.1588 24.4997 37.97 24.5H24.53C24.1322 24.5 23.7506 24.658 23.4693 24.9393C23.188 25.2206 23.03 25.6022 23.03 26C23.03 26.3978 23.188 26.7794 23.4693 27.0607C23.7506 27.342 24.1322 27.5 24.53 27.5H34.35L22.64 39.24H9C8.60218 39.24 8.22064 39.398 7.93934 39.6793C7.65804 39.9606 7.5 40.3422 7.5 40.74V55C7.5 55.3978 7.65804 55.7794 7.93934 56.0607C8.22064 56.342 8.60218 56.5 9 56.5H23.26C23.6578 56.5 24.0394 56.342 24.3207 56.0607C24.602 55.7794 24.76 55.3978 24.76 55V41.36L36.47 29.65V39.47Z" />
                          <path d="M55 7.5H9C8.60218 7.5 8.22064 7.65804 7.93934 7.93934C7.65804 8.22064 7.5 8.60218 7.5 9V34.3C7.5 34.6978 7.65804 35.0794 7.93934 35.3607C8.22064 35.642 8.60218 35.8 9 35.8C9.39782 35.8 9.77936 35.642 10.0607 35.3607C10.342 35.0794 10.5 34.6978 10.5 34.3V10.5H53.5V53.5H29.7C29.3022 53.5 28.9206 53.658 28.6393 53.9393C28.358 54.2206 28.2 54.6022 28.2 55C28.2 55.3978 28.358 55.7794 28.6393 56.0607C28.9206 56.342 29.3022 56.5 29.7 56.5H55C55.3978 56.5 55.7794 56.342 56.0607 56.0607C56.342 55.7794 56.5 55.3978 56.5 55V9C56.5 8.60218 56.342 8.22064 56.0607 7.93934C55.7794 7.65804 55.3978 7.5 55 7.5Z" />
                        </svg>
                      </div>
                      <span className="bg-success-grad fw-bold">S</span>
                      <h6 className="ser-title">SCALABLE</h6>
                    </div>
                </div>
            </div>

            </div>
        </div>
    </div>
    {/* End of 4x2 Features Grid */}
    </div>
    
    </div>
    
    <div id="xone-inner-2" className="row mt-4 align-items-center justify-content-center">
      <div className="col-12 mb-0">
        <div className="row">
          <div id="xonerator" className="col-12 col-md-7 mb-2 animatable fadeInRight">
            <div className="card bg-transparent shadow" style={{border: "3px solid #22c777", borderRadius: "10px"}}>
              <img className="card-img-top p-1 noselect" src="media/xonerator-1.gif" alt="build immersive microverses in xonerator" style={{borderRadius: "10px"}}/>
              <div className="card-body">
                <p className="card-text">Quickly design and customize Xones in <b className="badge" style={{ background: "#662D91", letterSpacing: "0.03em" }}>XONERATOR</b>, an intuitive <b>A-Frame scene builder</b> with pre-built objects, customizable components, and powerful features.</p>
              </div>
            </div>
          </div>
          <div id="solus" className="col-12 col-md-5 pt-4 pt-md-0 animatable fadeInLeft">
            <div className="profile-card bg-green1 bxs-green2 p-1"> {/* removed class "float-card-1 */}
              <div className="card bg-purp1 text-center shadow-md pb-0" style={{ borderRadius:"11px"}}>
              <div className="card-body">
                
                <h5 className="card-title fw-bold my-2 p-1 rounded-3" style={{backgroundColor:"rgba(255,255,255,0.25)", letterSpacing: "0.04em"}}>Cross-Platform</h5>
    
                <div className="row g-2 align-items-center justify-content-evenly">
                <div className="col-3">
                <div className="card bg-transparent tech-card border-0 text-center align-items-center">
                <h1 className="card-title py-3"><i className="fa-solid fa-desktop"></i></h1>
                </div>
                </div>
                <div className="col-3">
                <div className="card bg-transparent tech-card border-0 text-center align-items-center">
                <h1 className="card-title py-3"><i className="fa-solid fa-mobile-button"></i></h1>
                </div>
                </div>
                <div className="col-3">
                <div className="card bg-transparent tech-card border-0 text-center align-items-center">
                <h1 className="card-title py-3"><i className="fa-solid fa-vr-cardboard"></i></h1>
                </div>
                </div>
                </div>
                
                <h5 className="card-title fw-bold my-2 p-1 rounded-3" style={{backgroundColor:"rgba(255,255,255,0.25)", letterSpacing: "0.04em"}}>Cross-Browser</h5>
    
                <div className="row g-2 align-items-center justify-content-evenly">
                <div className="col-3">
                <div className="card bg-transparent tech-card border-0 text-center align-items-center">
                <h1 className="card-title pt-3"><i className="fa-brands fa-chrome"></i></h1>
                </div>
                </div>
                <div className="col-3">
                <div className="card bg-transparent tech-card border-0 text-center align-items-center">
                <h1 className="card-title pt-3"><i className="fa-brands fa-firefox"></i></h1>
                </div>
                </div>
                <div className="col-3">
                <div className="card bg-transparent tech-card border-0 text-center align-items-center">
                <h1 className="card-title pt-3"><i className="fa-brands fa-safari"></i></h1>
                </div>
                </div>
                </div>
    
                <div className="row g-2">
                <div className="col-12">
                  <hr />
                <a href="https://xone.land/" target="_blank" rel="noreferrer">
                  <button className="btn btn-lg btn-hover bg-blue-gradient btn-block w-100" type="button">
                  <h2 style={{ letterSpacing: "0.09em" }} className="text-light px-5 fw-bold">DEMO</h2>
                  <div className="badge bg-purp2 text-wrap" style={{ width: "99%" }}>Phase 3 Launch</div>
                  </button>
                  </a>
                </div>
                </div>
    
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    </div>
    {/* End of HONE YOUR XONE */}
    </section>

    {/* End of XONES Section */}

    <section id="feature-mob" className="theme-bg feature my-0 py-3 text-center">
    
    {/* Feature Mob */}
    <div className="container my-5 pb-1 pb-md-5">
        <div className="animation-circle-inverse"><i></i><i></i><i></i></div>
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 text-center">
          <h1 className="pb-3 display-5 fw-bold gradient-txt-green" style={{filter: "drop-shadow(0px 1px -1rem rgba(255,255,255,1))", letterSpacing: "0.04em"}}><span className="typewrite gradient-txt-pink" data-period="2000" data-type='[ "BUILD", "HOST", "SHARE", "STAKE" ]'>BUILD</span>-TO-EARN</h1>
          </div>
          <div className="col-11 col-lg-4 mob-left">
            
          <button type="button" className="btn bg-red-gradient text-light w-100 mb-4 p-2 px-3 p-md-3 p-lg-4 fs-4 position-relative animatable fadeInLeft">
          <h4 className="fs-4" style={{ letterSpacing: "0.04em" }}>Immersive XR microverses</h4>
{/*             <span className="position-absolute top-0 start-100 translate-middle p-2 bg-info border border-light rounded-circle">
              <span className="visually-hidden">Feature</span>
            </span> */}
          </button>
          
          <button type="button" className="btn bg-red-gradient text-light w-100 mb-4 p-2 px-3 p-md-3 p-lg-4 fs-4 position-relative animatable fadeInLeft">
          <h4 className="fs-4" style={{ letterSpacing: "0.04em" }}>Web3 traffic monetization</h4>
{/*             <span className="position-absolute top-0 start-100 translate-middle p-2 bg-info border border-light rounded-circle">
              <span className="visually-hidden">Feature</span>
            </span> */}
          </button>
          
          <button type="button" className="btn bg-pink-gradient text-light w-100 mb-4 p-2 px-3 p-md-3 p-lg-4 fs-4 position-relative animatable fadeInLeft">
          <h4 className="fs-4" style={{ letterSpacing: "0.04em" }}>Custom asset marketplace</h4>
{/*             <span className="position-absolute top-0 start-100 translate-middle p-2 bg-info border border-light rounded-circle">
              <span className="visually-hidden">Feature</span>
            </span> */}
          </button>
          
          <button type="button" className="btn bg-purple-gradient text-light w-100 mb-4 p-2 px-3 p-md-3 p-lg-4 fs-4 position-relative animatable fadeInLeft">
          <h4 className="fs-4" style={{ letterSpacing: "0.04em" }}>Modular &amp; scalable</h4>
{/*             <span className="position-absolute top-0 start-100 translate-middle p-2 bg-info border border-light rounded-circle">
              <span className="visually-hidden">Feature</span>
            </span> */}
          </button>
          
          </div>
          <div className="col-12 col-lg-4 future-mobile">
          {/* <img className="img-fluid" src="images/color-5/feature-mob.png" alt="feature-mob" /> */}
          <div className="iphone iphone-pink bxs-purp2 noselect" style={{position:"relative"}}>
            <div className="iphone-small-round-top"></div>
            <div className="iphone-round-top-left"></div>
            <div className="iphone-speaker"></div>
            <img className="iphone-screenshot" src="images/color-5/feature-mob-inset-800x1362.gif" alt="metaballs on mobile" />
            <div className="iphone-button"></div>
          </div>
          </div>
          <div className="col-11 col-lg-4 mob-right">
            
          <button type="button" className="btn bg-red-gradient text-light w-100 mb-4 p-2 px-3 p-md-3 p-lg-4 fs-4 position-relative animatable fadeInRight">
          <h4 className="fs-4" style={{ letterSpacing: "0.04em" }}>Powerful creativity tools</h4>
{/*             <span className="position-absolute top-0 start-0 translate-middle p-2 bg-info border border-light rounded-circle">
              <span className="visually-hidden">Feature</span>
            </span> */}
          </button>
          
          <button type="button" className="btn bg-red-gradient text-light w-100 mb-4 p-2 px-3 p-md-3 p-lg-4 fs-4 position-relative animatable fadeInRight">
          <h4 className="fs-4" style={{ letterSpacing: "0.04em" }}>DAO voting &amp; airdrops</h4>
{/*             <span className="position-absolute top-0 start-0 translate-middle p-2 bg-info border border-light rounded-circle">
              <span className="visually-hidden">Feature</span>
            </span> */}
          </button>
          
          <button type="button" className="btn bg-pink-gradient text-light w-100 mb-4 p-2 px-3 p-md-3 p-lg-4 fs-4 position-relative animatable fadeInRight">
          <h4 className="fs-4" style={{ letterSpacing: "0.04em" }}>Arweave content permanence</h4>
{/*             <span className="position-absolute top-0 start-0 translate-middle p-2 bg-info border border-light rounded-circle">
              <span className="visually-hidden">Feature</span>
            </span> */}
          </button>
          
          <button type="button" className="btn bg-purple-gradient text-light w-100 mb-4 p-2 px-3 p-md-3 p-lg-4 fs-4 position-relative animatable fadeInRight">
          <h4 className="fs-4" style={{ letterSpacing: "0.04em" }}>Decentralized &amp; on-chain</h4>
{/*             <span className="position-absolute top-0 start-0 translate-middle p-2 bg-info border border-light rounded-circle">
              <span className="visually-hidden">Feature</span>
            </span> */}
          </button>
          

          </div>
        </div>
    </div>
    {/* End of Feature Mob CL */}
    </section>
    
    {/* START OF PLASMAVERSE Section */}
    <section id="plasmaverse" className="container-fluid pt-5 px-1 px-lg-2 text-center align-items-center" style={{overflow: "visible"}}>
    <div id="link3" className="card xonecard mt-5 mx-2 mx-md-3 mx-lg-5 p-2 pb-0 align-items-center shadow-lg" style={{ border: "5px solid #22c777", borderRadius: "11px" }}>
    
    <div className="row service-6 mx-1 mx-md-3 mt-1 p-1 p-md-2 p-lg-3 align-items-center justify-content-center" style={{position: "relative", zIndex: "5"}}>
      <div className="col-12 col-lg-11 mb-3">        
        <div id="techstack" className="card mt-5 p-2 pb-3 p-md-5 bg-purp-opaque shadow" style={{borderRadius:"10px"}}>
          <div className="card-body">
            <h2 className="btn bg-dark-gradient rounded-pill px-5 py-3 fs-2 fw-bold text-white bxs-green2">Scalable Technology Stack</h2>
            <p className="card-text fs-3 px-md-5 py-3">We're building with A-Frame, a versatile and well-supported open source framework based on Three.js that leverages WebGL and WebXR to deliver "immersive interactive VR experiences that go beyond basic 360° content, making full use of positional tracking and controllers."</p>
            <div className="row g-4">
                <div className="col-12 col-sm-6 col-xl-3 wrap-service6-box">
                    <div className="card techcard h-100 border-0 bg-purp-opaque text-white mb-2 bxs-green3 animatable fadeInUp">
                        <div className="card-body">
                            <p className="card-text">An entity-component framework for composing XR experiences using HTML markup.</p>
                        </div>
                      <div className="card-footer">A-Frame</div>
                    </div>
              </div>
    
              <div className="col-12 col-sm-6 col-xl-3 wrap-service6-box">
                  <div className="card techcard h-100 border-0 bg-purp-opaque text-white mb-2 bxs-green3 animatable fadeInUp">
                      <div className="card-body">
                          <p className="card-text">A powerful cross-browser library widely used to create and display WebGL-based 3D content.</p>
                      </div>
                    <div className="card-footer">Three.js</div>
                  </div>
              </div>
    
              <div className="col-12 col-sm-6 col-xl-3 wrap-service6-box">
                  <div className="card techcard h-100 border-0 bg-purp-opaque text-white mb-2 bxs-green3 animatable fadeInUp">
                      <div className="card-body">
                          <p className="card-text">A set of browser API standards for rendering WebGL content to AR and VR devices.</p>
                      </div>
                    <div className="card-footer">WebXR</div>
                  </div>
              </div>
    
              <div className="col-12 col-sm-6 col-xl-3 wrap-service6-box">
                  <div className="card techcard h-100 border-0 bg-purp-opaque text-white mb-2 bxs-green3 animatable fadeInUp">
                      <div className="card-body">
                          <p className="card-text">An advanced API for processing interactive GPU-accelerated 2D and 3D graphics.</p>
                      </div>
                      <div className="card-footer">WebGL</div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* START of SHOWCASE (with 2x2 gif-grid snippet) */}
        <div className="col-12">
          <div className="mbp-wrapper py-5 justify-content-center align-items-center">
          <div id="showcase" className="showcase mx-auto">
              <div className="container">
                  <div className="row g-4 justify-content-center align-items-center">
                      <div className="col-12 col-md-6 col-lg-5 mb-2">
                        <div className="row">
                            <div className="col-md-6 mt-3 wrap-service6-box">
                                <div className="card h-100 border border-info bg-purple-gradient text-white mb-2 shadow">
                                <img className="card-img-top noselect" src="https://cdn.glitch.global/37011ed6-4b84-44e8-8f53-630683dd483b/vr-shop-rooms-demo.gif?v=1650942749880" alt="Storefront Xones with A-Frame" />
                                <div className="card-header">LIFESTYLE</div>
                                    <div className="card-body">
                                        {/* <h6 className="font-weight-medium text-white">Instant Solutions</h6> */}
                                        <p className="card-text">Storefronts, galleries, tours, interactive scenes.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mt-3 wrap-service6-box">
                                <div className="card h-100 border border-info bg-pink-gradient text-white mb-2 shadow">
                                <img className="card-img-top noselect" src="https://cloud.githubusercontent.com/assets/674727/24531440/0336e66e-156e-11e7-95c2-f2e6ebc0393d.gif" alt="Gaming Xones with A-Frame" />
                                <div className="card-header">GAMING</div>
                                    <div className="card-body">
                                        {/* <h6 className="font-weight-medium text-white">Powerful Techniques</h6> */}
                                        <p className="card-text">Gamify your Xones with contests, quests &amp; more!</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mt-3 wrap-service6-box">
                                <div className="card h-100 border border-info bg-blue-gradient text-white mb-2 shadow">
                                <img className="card-img-top noselect" src="https://cdn.glitch.global/37011ed6-4b84-44e8-8f53-630683dd483b/existing-demos.gif?v=1650942398231" alt="Productivity Xones with A-Frame" />
                                <div className="card-header">PRODUCTIVITY</div>
                                    <div className="card-body">
                                        {/* <h6 className="font-weight-medium text-white">Retargeting Market</h6> */}
                                        <p className="card-text">Meetings, townhalls, presentations, virtual offices.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mt-3 wrap-service6-box">
                                  <div className="card h-100 border border-info bg-green-gradient text-white mb-2 shadow">
                                  <img className="card-img-top noselect" src="https://cloud.githubusercontent.com/assets/674727/24531477/44272daa-156e-11e7-8ef9-d750ed430f3a.gif" alt="Entertainment Xones with A-Frame" />
                                  <div className="card-header">ENTERTAINMENT</div>
                                      <div className="card-body">
                                          {/* <h6 className="font-weight-medium text-white">Powerful Techniques</h6> */}
                                          <p className="card-text">Host public &amp; private events in the metaverse.</p>
                                      </div>
                                  </div>
                            </div>
                          </div>
                      </div>
                      <div className="col-12 col-md-6 col-lg-5 mt-lg-5">
                          <div className="row wrap-showcase pt-lg-5">
                              <div className="col-md-6">
                                  <div className="row">
                                    <div className="col-md-12 wrap-service6-box mb-4"><img src="https://cdn.glitch.global/37011ed6-4b84-44e8-8f53-630683dd483b/v0.3.0-inspector.gif?v=1650942907612" className="rounded img-shadow img-fluid border border-info noselect" alt="Xone Games with A-Frame" /></div>
                                    <div className="col-md-12 wrap-service6-box mb-4"><img src="https://user-images.githubusercontent.com/674727/41085457-f5429566-69eb-11e8-92e5-3210e4c6c4a0.gif" className="rounded img-shadow img-fluid border border-info noselect" alt="Xone Scapes with A-Frame" /></div>
                                    <div className="col-md-12 wrap-service6-box mb-4"><img src="https://cdn.glitch.global/37011ed6-4b84-44e8-8f53-630683dd483b/ar-comic.gif?v=1650941998569" className="rounded img-shadow img-fluid border border-info noselect" alt="Xone Games with A-Frame" /></div>
                                  </div>
                              </div>
                              <div className="col-md-6 uneven-box">
                                  <div className="row">
                                    <div className="col-md-12 wrap-service6-box mb-4"><img src="https://cdn.glitch.global/37011ed6-4b84-44e8-8f53-630683dd483b/apainter_painting.gif?v=1650942015716" className="rounded img-shadow img-fluid border border-info noselect" alt="Xone Events with A-Frame" /></div>
                                    <div className="col-md-12 wrap-service6-box mb-4"><img src="https://cloud.githubusercontent.com/assets/674727/25109861/b8e9ec48-2394-11e7-8f2d-ea1cd9df69c8.gif" className="rounded img-shadow img-fluid border border-info noselect" alt="Xone Hangouts with A-Frame" /></div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="col-12 col-md-12 col-lg-2 mt-lg-5">
                        <a className="btn service-btn btn-md text-white noselect" href="https://aframe.io/showcase/" target="_blank" rel="noreferrer"><span>SHOWCASE</span></a>
                      </div>
                  </div>
              </div>
              {/* Plasmaverse video-bg :: 
              <video autoPlay muted loop id="vidXone" className="mt-1 mx-auto bxs-green rounded-3">
                <source src="https://cdn.glitch.global/84180755-58e4-4c80-b543-77cacf869330/xone-bg-14s.mp4?v=1647881876712" type="video/mp4" />
              </video> */}
          </div>
          {/* End of showcase with 2x2 gif-grid */}   
          </div>
    
        </div>
    
      <div className="col-12 p-2"></div>
    
    </div>
    <div id="plasmagrid" className="container-fluid mx-0 py-2"></div>
    </div>
    </section>
    {/* End of XONE Section */}

{/* START OF tokenomics - Service1 3x1 grid */}
<div id="tokenomics" className="service-1 pb-5 pt-2">
  <div className="container my-0 mb-5">
    <div className="hero-header my-0 mb-2">
      <h1 className="pb-3 text-center display-5 fw-bold lh-2 text-white" style={{letterSpacing:"0.04em"}}>TOKENOMICS</h1>
    </div>

    <div className="row mx-1 mx-md-0 mb-4">
      <div className="col-12 col-md-11 col-lg-9 mx-auto text-center">
        <p className="lead fs-3 text-white">The Plasmaverse will leverage three Solana tokens to facilitate governance of <strong>ownership and access</strong> to digital assets and estates, and drive the on-chain <strong>exchange of utility and value</strong>.</p>
      </div>
    </div>

    <div className="row mx-1 mx-md-4 mx-sm-4 mx-xs-2">
      <div className="col-12 col-lg-4 wrap-service1-box mb-4">
        <div id="mball-token" className="card h-100 token-card-blue shadow">
          <div className="card-header py-3">
            <div className="row align-items-center mx-auto">
             <div className="col-auto h-100">
             <img src="https://cdn.glitch.global/37011ed6-4b84-44e8-8f53-630683dd483b/logo-mball-200x200.png?v=1650931482950" alt="token logo" width="75px" className="tokenicon-img rounded-circle border border-4 border-body" />
              </div>
              <div className="col-auto h-100">
                <p className="badge bg-light text-dark border border-secondary fw-bold mb-1" style={{ letterSpacing: "0.03em" }}>Whitelist Token</p>
                <h5 className="my-0 fw-bold" style={{letterSpacing: "0.03em", paddingLeft: "0.5em"}}>$MBALL</h5>
              </div>
            </div>
          </div>
          <div className="card-body pb-1 m-auto text-center text-white">
          <span>Total Supply</span>
          <h3 className="fw-medium">19,326,120</h3>
          <p className="pb-1 card-text text-start">A 1:1 token that whitelists holders, allowing them to mint a Metaball NFT. Funds from the mint are allocated as follows:</p>
            <div className="container p-1 pb-2 mb-0">
              <div className="row align-items-center text-start">
                <div className="col-11 col-lg-10">
                  <div className="d-flex my-1 my-md-2 align-items-center">
                    <div className="display-5" style={{paddingRight:"15px"}}><span className="gradient-txt-light"><i className="icon-wrench"></i></span></div>
                    <div className="">
                      <h3 className="mb-0 fw-medium">40%</h3>
                      <h6 className="subtitle fw-normal">Development</h6>
                    </div>
                  </div>
                </div>
                <div className="col-11 col-lg-10">
                  <div className="d-flex my-1 my-md-2 align-items-center">
                    <div className="display-5" style={{paddingRight:"15px"}}><span className="gradient-txt-light"><i className="icon-people"></i></span></div>
                    <div className="">
                      <h3 className="mb-0 fw-medium">30%</h3>
                      <h6 className="subtitle fw-normal">Design</h6>
                    </div>
                  </div>
                </div>
                <div className="col-11 col-lg-10">
                  <div className="d-flex my-1 my-md-2 align-items-center">
                    <div className="display-5" style={{paddingRight:"15px"}}><span className="gradient-txt-light"><i className="icon-pencil"></i></span></div>
                    <div className="">
                      <h3 className="mb-0 fw-medium">20%</h3>
                      <h6 className="subtitle fw-normal">Marketing</h6>
                    </div>
                  </div>
                </div>
                <div className="col-11 col-lg-10">
                  <div className="d-flex my-1 my-md-2 align-items-center">
                    <div className="display-5" style={{paddingRight:"15px"}}><span className="gradient-txt-light"><i className="icon-grid"></i></span></div>
                    <div className="">
                      <h3 className="mb-0 fw-medium">5% / 5%</h3>
                      <h6 className="subtitle fw-normal">DAO / Creators</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="card-footer text-center">
          <div className="row g-2">
            <div className="col-12">
            <a href="/#faq">
            <button className="btn-learn btn btn-lg btn-light w-100 hstack py-1 fs-5" type="button">
              {/* <h5 className="my-0" style={{ letterSpacing: "0.05em", fontWeight: 500 }}>Track on</h5> */}
              <span className="badge bg-transparent"><i className="fa-solid fa-info"></i></span> LEARN MORE
            </button></a>
            </div>
            <div className="col-12">
              <a href="https://solscan.io/token/E359HKTV192s4kpg4QXTmj7eQ6fzvsL2KbU9QJGDrM3e" target="_blank" rel="noreferrer">
            <button className="btn-solscan btn btn-lg btn-secondary w-100 hstack py-1 fs-5" type="button">
              {/* <h5 className="my-0" style={{ letterSpacing: "0.05em", fontWeight: 500 }}>Track on</h5> */}
              <span className="badge bg-dark"><img className="w-100 px-1" alt="View $MBALL on Solscan Explorer" title="View $MBALL on Solscan Explorer" src="/images/partners/logo-solscan-explorer.png" style={{maxHeight:"18px"}}/></span> SOLSCAN
            </button></a>
            </div>
            <div className="col-12">
              <a href="https://explorer.solana.com/address/E359HKTV192s4kpg4QXTmj7eQ6fzvsL2KbU9QJGDrM3e" target="_blank" rel="noreferrer">
            <button className="btn btn-lg btn-dark w-100 hstack fs-5 pt-1" type="button">
              {/* <h5 className="my-0" style={{ letterSpacing: "0.05em", fontWeight: 500 }}>Track on</h5> */}
              <img className="px-5 px-md-3 px-lg-2" alt="View $MBALL on Solana Explorer" title="View $MBALL on Solana Explorer" src="/images/partners/logo-solana-explorer.svg" />
            </button></a>
            </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 col-lg-4 wrap-service1-box mb-4">
        <div id="xone-token" className="card h-100 token-card-purp shadow">
          <div className="card-header py-3">
            <div className="row align-items-center mx-auto">
             <div className="col-auto h-100">
             <img src="https://cdn.glitch.global/37011ed6-4b84-44e8-8f53-630683dd483b/xone-icon-dark.svg?v=1650931998099" alt="$XONE spl-token logo" width="75px" className="tokenicon-img rounded-circle border border-4 border-body" />
              </div>
              <div className="col-auto h-100">
                <p className="badge bg-light text-dark border border-secondary fw-bold mb-1" style={{ letterSpacing: "0.03em" }}>Estate Token</p>
                <h5 className="my-0 fw-bold" style={{letterSpacing: "0.03em", paddingLeft: "11px"}}>$XONE</h5>
              </div>
            </div>
          </div>
          <div className="card-body pb-1 m-auto text-center text-white">
          <span>Total Supply</span>
          <h3 className="fw-medium">38,652,240</h3>
          <p className="pb-1 card-text text-start">A 2:1 token used to claim Xones on the Plasmaverse. $XONE is burnt when estates are claimed; they are allocated as follows:</p>
            <div className="container p-1 pb-2 mb-0">
              <div className="row align-items-center text-start">
                <div className="col-11 col-lg-10">
                  <div className="d-flex my-1 my-md-2 align-items-center">
                    <div className="display-5" style={{paddingRight:"15px"}}><span className="gradient-txt-light"><i className="icon-location-pin"></i></span></div>
                    <div className="">
                      <h3 className="mb-0 fw-medium">8</h3>
                      <h6 className="subtitle fw-normal">Home Xones</h6>
                    </div>
                  </div>
                </div>
                <div className="col-11 col-lg-10">
                  <div className="d-flex my-1 my-md-2 align-items-center">
                    <div className="display-5" style={{paddingRight:"15px"}}><span className="gradient-txt-light"><i className="icon-emotsmile"></i></span></div>
                    <div className="">
                      <h3 className="mb-0 fw-medium">44K</h3>
                      <h6 className="subtitle fw-normal">NFT Holders</h6>
                    </div>
                  </div>
                </div>
                <div className="col-11 col-lg-10">
                  <div className="d-flex my-1 my-md-2 align-items-center">
                    <div className="display-5" style={{paddingRight:"15px"}}><span className="gradient-txt-light"><i className="icon-event"></i></span></div>
                    <div className="">
                      <h3 className="mb-0 fw-medium">11%</h3>
                      <h6 className="subtitle fw-normal">Reserved</h6>
                    </div>
                  </div>
                </div>
                <div className="col-11 col-lg-10">
                  <div className="d-flex my-1 my-md-2 align-items-center">
                    <div className="display-5" style={{paddingRight:"15px"}}><span className="gradient-txt-light"><i className="icon-lock-open"></i></span></div>
                    <div className="">
                      <h3 className="mb-0 fw-medium">88%</h3>
                      <h6 className="subtitle fw-normal">Unlockable</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="card-footer text-center">
          <div className="row g-2">
            <div className="col-12">
              <a href="/#faq">
            <button className="btn-learn btn btn-lg btn-light w-100 hstack py-1 fs-5" type="button">
              {/* <h5 className="my-0" style={{ letterSpacing: "0.05em", fontWeight: 500 }}>Track on</h5> */}
              <span className="badge bg-transparent"><i className="fa-solid fa-info"></i></span> LEARN MORE
            </button></a>
            </div>
            <div className="col-12">
              <a href="https://solscan.io/token/APkf6LAn4uiSHWBFuXiaq6GaJQzNHNzcK5TSsxRUA3bh" target="_blank" rel="noreferrer">
            <button className="btn-solscan btn btn-lg btn-secondary w-100 hstack py-1 fs-5" type="button">
              {/* <h5 className="my-0" style={{ letterSpacing: "0.05em", fontWeight: 500 }}>Track on</h5> */}
              <span className="badge bg-dark"><img className="w-100 px-1" alt="View $XONE on Solscan Explorer" src="/images/partners/logo-solscan-explorer.png" style={{maxHeight:"18px"}}/></span> SOLSCAN
            </button></a>
              </div>
              <div className="col-12">
              <a href="https://explorer.solana.com/address/APkf6LAn4uiSHWBFuXiaq6GaJQzNHNzcK5TSsxRUA3bh" target="_blank" rel="noreferrer">
            <button className="btn btn-lg btn-dark w-100 hstack fs-5 pt-1" type="button">
              {/* <h5 className="my-0" style={{ letterSpacing: "0.05em", fontWeight: 500 }}>Track on</h5> */}
              <img className="px-5 px-md-3 px-lg-2" alt="View $XONE on Solana Explorer" src="/images/partners/logo-solana-explorer.svg" />
            </button></a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 col-lg-4 wrap-service1-box mb-4">
        <div id="plasma-token" className="card h-100 token-card-pink shadow">
          <div className="card-header py-3">
            <div className="row align-items-center mx-auto">
             <div className="col-auto h-100">
             <img src="https://cdn.glitch.global/37011ed6-4b84-44e8-8f53-630683dd483b/logo-plasma-483x483.png?v=1650932238054" alt="token logo" width="75px" className="tokenicon-img rounded-circle border border-4 border-body" />
              </div>
              <div className="col-auto h-100">
              <p className="badge bg-light text-dark border border-secondary fw-bold mb-1" style={{ letterSpacing: "0.03em" }}>Utility Token</p>
                <h5 className="my-0 fw-bold" style={{letterSpacing: "0.03em"}}>$PLASMA</h5>
              </div>
            </div>
          </div>
          <div className="card-body pb-1 m-auto text-center text-white">
          <span>Total Supply</span>
          <h3 className="fw-medium">22 Trillion</h3>
          <p className="pb-1 card-text text-start">An spl-token that will be used to enable value exchange within the ecosystem. $PLASMA tokens are allocated as follows:</p>
            <div className="container p-1 pb-2 mb-0">
              <div className="row align-items-center text-start">
                <div className="col-11 col-lg-10">
                  <div className="d-flex my-1 my-md-2 align-items-center">
                    <div className="display-5" style={{paddingRight:"15px"}}><span className="gradient-txt-light"><i className="icon-fire"></i></span></div>
                    <div className="">
                      <h3 className="mb-0 fw-medium">50%</h3>
                      <h6 className="subtitle fw-normal">Burn</h6>
                    </div>
                  </div>
                </div>
                <div className="col-11 col-lg-10">
                  <div className="d-flex my-1 my-md-2 align-items-center">
                    <div className="display-5" style={{paddingRight:"15px"}}><span className="gradient-txt-light"><i className="icon-energy"></i></span></div>
                    <div className="">
                      <h3 className="mb-0 fw-medium">20%</h3>
                      <h6 className="subtitle fw-normal">Plasmaverse</h6>
                    </div>
                  </div>
                </div>
                <div className="col-11 col-lg-10">
                  <div className="d-flex my-1 my-md-2 align-items-center">
                    <div className="display-5" style={{paddingRight:"15px"}}><span className="gradient-txt-light"><i className="icon-lock"></i></span></div>
                    <div className="">
                      <h3 className="mb-0 fw-medium">20%</h3>
                      <h6 className="subtitle fw-normal">Liquidity</h6>
                    </div>
                  </div>
                </div>
                <div className="col-11 col-lg-10">
                  <div className="d-flex my-1 my-md-2 align-items-center">
                    <div className="display-5" style={{paddingRight:"15px"}}><span className="gradient-txt-light"><i className="icon-trophy"></i></span></div>
                    <div className="">
                      <h3 className="mb-0 fw-medium">10%</h3>
                      <h6 className="subtitle fw-normal">Xoner Fund</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="card-footer text-center">
          <div className="row g-2">
            <div className="col-12">
              <a href="/#faq">
            <button className="btn-learn btn btn-lg btn-light w-100 hstack py-1 fs-5" type="button">
              {/* <h5 className="my-0" style={{ letterSpacing: "0.05em", fontWeight: 500 }}>Track on</h5> */}
              <span className="badge bg-transparent"><i className="fa-solid fa-info"></i></span> LEARN MORE
            </button></a>
            </div>
            <div className="col-12">
              <a href="https://solscan.io/token/7gDoUkqmkjX8BEVrkYQs6cawFB4ronky8AfkCEyHbzB2" target="_blank" rel="noreferrer">
            <button className="btn-solscan btn btn-lg btn-secondary w-100 hstack py-1 fs-5" type="button">
              {/* <h5 className="my-0" style={{ letterSpacing: "0.05em", fontWeight: 500 }}>Track on</h5> */}
              <span className="badge bg-dark"><img className="w-100 px-1" alt="View $PLASMA on Solscan Explorer" src="/images/partners/logo-solscan-explorer.png" style={{maxHeight:"18px"}}/></span> SOLSCAN
            </button></a>
              </div>
              <div className="col-12">
              <a href="https://explorer.solana.com/address/7gDoUkqmkjX8BEVrkYQs6cawFB4ronky8AfkCEyHbzB2" target="_blank" rel="noreferrer">
            <button className="btn btn-lg btn-dark w-100 hstack fs-5 pt-1" type="button">
              {/* <h5 className="my-0" style={{ letterSpacing: "0.05em", fontWeight: 500 }}>Track on</h5> */}
              <img className="px-5 px-md-3 px-lg-2" alt="View $PLASMA on Solana Explorer" src="/images/partners/logo-solana-explorer.svg" />
            </button></a>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div className="col-12 col-md-12 mt-3 p-5 text-center">
        {/* <a className="btn service-btn text-white btn-md border-0" href="#f1"><span>View Details</span></a> */}
      </div>
    </div>

{/* 
      <div classNamee="row">
        <button className="btn btn-lg btn-outline-info px-5" type="button" disabled>
        <h2 style={{ letterSpacing: "0.1em" }} className="text-light"><em>Imagine. Realize.</em></h2>
        <div className="badge bg-info text-wrap" style={{ width: "95%" }}>Projected Launch : Phase 5</div>
        </button>
      </div>
*/}
    </div>
</div>
{/* End of Xtrood (with 3x1 grid Snippet) */}
    
    {/* START OF TEAMS Section */}
    <section id="team" className="my-0 mt-5 pb-5 py-2 px-3 p-lg-5 overflow-visible">
      <div className="container mx-auto mb-5 py-4 card-shadow-lg" style={{ marginTop: "-200px", position: "relative", zIndex: 11, background: "linear-gradient(-15deg, rgba(0,183,191,1), rgba(47,0,65,1))", borderRadius: "11px" }}>
        
        {/* Start of DELIVER VALUE subsection (with gif-video) :: 
        <div className="container-fluid p-4 pb-0 my-5 text-center">
        <h1 className="display-4 fw-bold lh-2">Deliver Value, Earn From Your Traffic</h1>
        <div className="col-lg-7 mx-auto">
          <p className="lead mb-2">Add areas, artifacts and automatons to your Xone in <b className="badge" style={{ background: "#662D91", letterSpacing: "0.03em" }}>XONERATOR</b>. Engage with Xone visitors, gather information, and automate workflows. Earn $PLASMA through <b>monetized traffic</b> and <b>automaton transactions</b>.</p>
        </div>
        <div className="mt-5">
          <div className="container px-0 px-md-2 px-lg-5">
            <img src="https://cdn.glitch.global/37011ed6-4b84-44e8-8f53-630683dd483b/xtrood_logo_text.png?v=1647382314441" className="xtrood-logo img-fluid p-5 pb-0 mt-5" alt="xtrood xone builder logo" width="400" height="auto" loading="lazy" />
            <img src="https://cdn.glitch.global/37011ed6-4b84-44e8-8f53-630683dd483b/xone-builder-aframe-inspector-registry.gif?v=1647314963979" className="img-fluid border border-info rounded-3 shadow p-1 mt-5" style={{ background: "rgba(255,255,255,0.11)" }} alt="aframe inspector xtrood xone builder" width="800" height="650" loading="lazy" />
          </div>
        </div>
        </div>
        <div className="col-lg-7 mx-auto text-center">
          <div className="d-grid gap-2 d-sm-flex justify-content-sm-center align-items-center mt-3 mb-5">
            <a href="https://xone.land/" target="_blank" rel="noreferrer"><button type="button" className="btn btn-info btn-lg px-4 me-sm-3 w-100">XONE DEMO [ SOLOS ]</button></a>
            <a href="https://plasmaver.se/" target="_blank" rel="noreferrer"><button type="button" className="btn btn-danger btn-lg px-4 w-100">PLASMAVERSE DEMO</button></a>
          </div>
        </div>
       */}
       
        {/* START of TEAM-MEMBERS Subsection */}
        <div id="team-members" className="row m-0 pb-2 justify-content-center">
          <div className="col-12">
            <h1 className="pb-3 text-center display-5 fw-bold lh-2 text-white" style={{letterSpacing:"0.04em"}}>OUR TEAM</h1>
          </div>
          <div className="col-12 col-md-6 col-lg-3 mb-3">
            <div className="card h-100 bg-transparent card-shadow text-center my-2 animatable fadeInUp">
            <img className="card-img-top noselect" src="images/team/ganesh-metaball-nft-avatar.png" alt="Ganesh, Development (Metaball Avatar)" />
            {/* <svg className="bd-placeholder-img card-img-top" width="100%" height="180" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Image cap" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#868e96"></rect><text x="50%" y="50%" fill="#dee2e6" dy=".3em">Image cap</text></svg> */}
            <div className="card-body">
                <h5 className="card-title">MrH0DL</h5>
                <h6 className="card-subtitle mb-3 fw-light fst-italic">Development</h6>
                <p className="card-text">A humble jack-of-all-trades with a neglected business degree and a passion for disruptive innovation.</p>
              </div>
              <div className="card-footer">
                <div className="row mx-auto g-2 justify-content-center align-items-center">
                  <div className="col-auto">
                    <a href="https://t.me/gnshr" target="_blank" rel="noreferrer"><button className="btn btn-info rounded-pill align-items-center"><i className="px-1 fa-brands fa-telegram team-social-icon-fa" style={{fontSize: "25px"}}></i></button></a>
                  </div>
                  <div className="col-auto">
                    <a href="https://twitter.com/MisterH0DL" target="_blank" rel="noreferrer"><button className="btn btn-info rounded-pill align-items-center"><i className="px-1 fa-brands fa-twitter team-social-icon-fa" style={{fontSize: "24px"}}></i></button></a>
                  </div>
                  <div className="col-auto">
                  <a href="https://linkedin.com/in/gnshr" target="_blank" rel="noreferrer"><button className="btn btn-info rounded-pill align-items-center"><i className="px-1 fa-brands fa-linkedin team-social-icon-fa" style={{fontSize: "24px"}}></i></button></a>
                </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3 mb-3">
            <div className="card h-100 bg-transparent card-shadow text-center my-2 animatable fadeInUp">
            <img className="card-img-top noselect" src="images/team/heri-metaball-nft-avatar.png" alt="Heri, Design (Metaball Avatar)" />
            {/* <svg className="bd-placeholder-img card-img-top" width="100%" height="180" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Image cap" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#868e96"></rect><text x="50%" y="50%" fill="#dee2e6" dy=".3em">Image cap</text></svg> */}
            <div className="card-body">
                <h5 className="card-title">HERI</h5>
                <h6 className="card-subtitle mb-3 fw-light fst-italic">Design</h6>
                <p className="card-text">A driven freelance artist with a sharp eye, extensive portfolio, and limitless creativity at his disposal.</p>
              </div>
              <div className="card-footer">
                <div className="row mx-auto g-2 justify-content-center align-items-center">
                  <div className="col-auto">
                    <a href="https://t.me/gnshr" target="_blank" rel="noreferrer"><button className="btn btn-info rounded-pill align-items-center"><i className="px-1 fa-brands fa-telegram team-social-icon-fa" style={{fontSize: "25px"}}></i></button></a>
                  </div>
                  <div className="col-auto">
                    <a href="https://www.vecteezy.com/members/heriyusuf" target="_blank" rel="noreferrer"><button className="btn btn-info rounded-pill align-items-center"><i className="px-1"><img src="icons/logo-vecteezy.webp" className="team-social-icon" height="24px" alt="visit vecteezy profile" /></i></button></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3 mb-3">
            <div className="card h-100 bg-transparent card-shadow text-center my-2 animatable fadeInUp">
            <img className="card-img-top noselect" src="images/team/arvin-metaball-nft-avatar.png" alt="Arvin, Marketing (Metaball Avatar)" />
            {/* <svg className="bd-placeholder-img card-img-top" width="100%" height="180" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Image cap" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#868e96"></rect><text x="50%" y="50%" fill="#dee2e6" dy=".3em">Image cap</text></svg> */}
            <div className="card-body">
                <h5 className="card-title">MrArvino</h5>
                <h6 className="card-subtitle mb-3 fw-light fst-italic">Marketing</h6>
                <p className="card-text">Energetic, outgoing, and an overall great guy to talk to about Metaball NFTs, Xones and the Plasmaverse.</p>
              </div>
              <div className="card-footer">
              <div className="row mx-auto g-2 justify-content-center align-items-center">
                <div className="col-auto">
                  <a href="https://t.me/arviinno" target="_blank" rel="noreferrer"><button className="btn btn-info rounded-pill align-items-center"><i className="px-1 fa-brands fa-telegram team-social-icon-fa" style={{fontSize: "25px"}}></i></button></a>
                </div>
                <div className="col-auto">
                  <a href="https://twitter.com/MrArvino" target="_blank" rel="noreferrer"><button className="btn btn-info rounded-pill align-items-center"><i className="px-1 fa-brands fa-twitter team-social-icon-fa" style={{fontSize: "24px"}}></i></button></a>
                </div>
                <div className="col-auto">
                  <a href="https://www.linkedin.com/in/bekir-%C3%A7etin-171a11215/" target="_blank" rel="noreferrer"><button className="btn btn-info rounded-pill align-items-center"><i className="px-1 fa-brands fa-linkedin team-social-icon-fa" style={{fontSize: "24px"}}></i></button></a>
                </div>
              </div>
            </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3 mb-3">
            <div className="card h-100 bg-transparent card-shadow text-center my-2 animatable fadeInUp">
            <img className="card-img-top noselect" src="images/team/vee-metaball-nft-avatar.png" alt="Vee, Communications (Metaball Avatar)" />
            {/* <svg className="bd-placeholder-img card-img-top" width="100%" height="180" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Image cap" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#868e96"></rect><text x="50%" y="50%" fill="#dee2e6" dy=".3em">Image cap</text></svg> */}
            <div className="card-body">
                <h5 className="card-title">VEE</h5>
                <h6 className="card-subtitle mb-3 fw-light fst-italic">Communications</h6>
                <p className="card-text">A social butterfly who enjoys authentic thai cuisine, deep tissue massages and the company of her guinea pigs.</p>
            </div>
            <div className="card-footer">
              <div className="row mx-auto g-2 justify-content-center align-items-center">
                <div className="col-auto">
                  <a href="https://t.me/metaballix" target="_blank" rel="noreferrer"><button className="btn btn-info rounded-pill align-items-center"><i className="px-1 fa-brands fa-telegram team-social-icon-fa" style={{fontSize: "25px"}}></i></button></a>
                </div>
                <div className="col-auto">
                  <a href="https://twitter.com/MisterH0DL" target="_blank" rel="noreferrer"><button className="btn btn-info rounded-pill align-items-center"><i className="px-1 fa-brands fa-twitter team-social-icon-fa" style={{fontSize: "24px"}}></i></button></a>
                </div>
                <div className="col-auto">
                  <a href="https://www.linkedin.com/in/veedeeta/" target="_blank" rel="noreferrer"><button className="btn btn-info rounded-pill align-items-center"><i className="px-1 fa-brands fa-linkedin team-social-icon-fa" style={{fontSize: "24px"}}></i></button></a>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
        {/* End of Team Members 3x1 */}
        <div className="row justify-content-center">
          <div className="col-11 col-md-10 col-lg-9 mx-auto text-center">
            <p className="lead"><small>We're expanding our autonomous team to include more might! If the project calls to you and you feel like you can contribute to its development, feel free to reach out to us directly: <b className="badge" style={{ background: "#662D91", letterSpacing: "0.03em" }}>CONTACT US</b></small></p>
          </div>
        </div>

      </div>
    </section>
    {/* End of Team section */}
    
    {/* <div className="b-divider"></div> */}
    <div className="container-fluid border-0 rounded-0 mx-0 px-0" style={{marginTop:"-1px", position:"relative"}}>
      <svg className="separator sep-team-roadmap" viewBox="0.1 0.1 180 40" preserveAspectRatio="none">
        <g transform="translate(-18.298844,-77.973964)">
            <path style={{fill:"#00C4CC"}} d="M 31.615583,86.351641 H 192.16499 v 26.901969 c 0,0 -32.03411,-14.237983 -59.62682,-12.72484 -22.34188,1.2252 -54.779359,9.72634 -54.779359,9.72634 0,0 -22.029534,3.62882 -34.471238,-1.88988 -12.441702,-5.51871 -11.67199,-22.013589 -11.67199,-22.013589 z" />
            <path style={{fill:"#380056"}} d="M 18.441597,78.106256 H 198.58126 v 39.288614 c 0,0 -43.10672,-27.825245 -73.47599,-19.687823 -30.369264,8.137423 -46.832208,12.548653 -46.832208,12.548653 0,0 -32.775418,8.05972 -46.735258,0 C 17.577964,102.19598 18.441597,78.106256 18.441597,78.106256 Z" />
        </g>
      </svg>
    </div>

{/* Start of ROADMAP Section */}
<section id="roadmap" className="pt-5 pb-0 mb-0 overflow-visible">
<div className="container rounded-3 mb-0 p-3 pt-0 px-2">
<h1 className="py-3 display-5 fw-bold lh-2 text-center text-white" style={{letterSpacing:"0.04em"}}>ROADMAP</h1>
</div>

<div className="roadmap1 py-2 mx-auto">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-lg-2 col-md-3">
        <div className="nav flex-column nav-pills rmv2 noselect" id="v-pills-tab" role="tablist">
          <a id="rm-phase1-link" className="rm-navlink nav-link active complete position-relative" data-bs-toggle="pill" href="#rm-phase1" data-bs-target="#rm-phase1" role="tab" aria-controls="rm-phase1" aria-expanded="true" aria-selected="true" type="button">
          <span className="fs-1 fs-sm-2 fw-bold">1</span>
                                      {/* <img src="https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/testimonial/1.jpg" alt="Phase 1" className="rounded-circle" /> */}
                                  </a>
          <a id="rm-phase2-link" className="rm-navlink nav-link" data-bs-toggle="pill" href="#rm-phase2" data-bs-target="#rm-phase2" role="tab" aria-controls="rm-phase2" aria-expanded="true" aria-selected="false" type="button">
          <span className="fs-1 fs-sm-2 fw-bold">2</span>
                                     {/* <img src="https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/testimonial/2.jpg" alt="Phase 2" className="rounded-circle" /> */}
                                  </a>
          <a id="rm-phase3-link" className="rm-navlink nav-link" data-bs-toggle="pill" href="#rm-phase3" data-bs-target="#rm-phase3" role="tab" aria-controls="rm-phase3" aria-expanded="true" aria-selected="false" type="button">
          <span className="fs-1 fs-sm-2 fw-bold">3</span>
                                     {/* <img src="https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/testimonial/3.jpg" alt="Phase 3" className="rounded-circle" /> */}
                                  </a>
          <a id="rm-phase4-link" className="rm-navlink nav-link" data-bs-toggle="pill" href="#rm-phase4" data-bs-target="#rm-phase4" role="tab" aria-controls="rm-phase4" aria-expanded="true" aria-selected="false" type="button">
          <span className="fs-1 fs-sm-2 fw-bold">4</span>
                                     {/* <img src="https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/testimonial/4.jpg" alt="Phase 4" className="rounded-circle" /> */}
                                  </a>
          <a id="rm-phase5-link" className="rm-navlink nav-link" data-bs-toggle="pill" href="#rm-phase5" data-bs-target="#rm-phase5" role="tab" aria-controls="rm-phase5" aria-expanded="true" aria-selected="false" type="button">
          <span className="fs-1 fs-sm-2 fw-bold">5</span>
                                     {/* <img src="https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/testimonial/1.jpg" alt="Phase 4" className="rounded-circle" /> */}
                                  </a>
        </div>
      </div>
      <div className="col-lg-9 col-md-8 ml-auto align-self-start mt-1 mt-md-2">
        <div className="tab-content" id="v-pills-tabContent">
          {/* Start of List Group : PHASE 1 */}
          <div className="tab-pane fade show active" id="rm-phase1" role="tabpanel" aria-labelledby="rm-phase1-link">
            <div className="col-auto">
            <div id="rm-p1-1" className="rm-content">
              <h2 className="rm-phase-heading p-1 px-3 mb-0 fs-1 shadow">Phase 1 <span className="fw-light" style={{color:"#f9f9f9"}}>_novum</span></h2>
              <div className="rm-title badge d-flex shadow"><h3 className="fs-4 my-auto">Conceptualization</h3></div>
                {/* <p className="description">Integer males uada tellus lorem, et condimentum neque commodo Integer males uada tellus lorem, et condimentum neque commodo</p> */}
            <div className="list-group list-group-flush mx-1 mx-md-3">
            <a href="/#rm-p1-1" className="list-group-item list-group-item-action d-flex gap-3 py-3" aria-current="true">
            {/* <img src="https://github.com/twbs.png" alt="twbs" width="32" height="32" className="rounded-circle flex-shrink-0 mt-1" /> */}
            <div className="d-flex gap-3 w-100 justify-content-between">
            <div>
            {/* <h6 className="mb-0">List group item heading</h6> */}
            <p className="mb-0">Public launch of Metaball Minter at metaball.world with 11,000 NFTs in Series One.</p>
            </div>
            <span className="opacity-50 text-nowrap"><i className="fa-solid fa-circle-check"></i></span>
            </div>
            </a>
            <a href="/#rm-p1-1" className="list-group-item list-group-item-action d-flex gap-3 py-3" aria-current="true">
            {/* <img src="https://github.com/twbs.png" alt="twbs" width="32" height="32" className="rounded-circle flex-shrink-0 mt-1" /> */}
            <div className="d-flex gap-3 w-100 justify-content-between">
            <div>
            {/* <h6 className="mb-0">Another title here</h6> */}
            <p className="mb-0">Limited demo of xone hub Solus showcasing compatibility is accessible at xone.land.</p>
            </div>
            <span className="opacity-50 text-nowrap"><i className="fa-solid fa-circle-check"></i></span>
            </div>
            </a>
            <a href="/#rm-p1-1" className="list-group-item list-group-item-action d-flex gap-3 py-3" aria-current="true">
            {/* <img src="https://github.com/twbs.png" alt="twbs" width="32" height="32" className="rounded-circle flex-shrink-0 mt-1" /> */}
            <div className="d-flex gap-3 w-100 justify-content-between">
            <div>
            {/* <h6 className="mb-0">Another title here</h6> */}
            <p className="mb-0">Implementation of growth campaigns to gain traction and attain trade volume targets.</p>
            </div>
            <small className="opacity-50 text-nowrap"><i className="fa-regular fa-circle"></i></small>
            </div>
            </a>
            <a href="/#rm-p2-1" className="list-group-item list-group-item-action d-flex gap-3 py-3" aria-current="true">
              {/* <img src="https://github.com/twbs.png" alt="twbs" width="32" height="32" className="rounded-circle flex-shrink-0 mt-1" /> */}
              <div className="d-flex gap-3 w-100 justify-content-between">
              <div>
              {/* <h6 className="mb-0">Another title here</h6> */}
              <p className="mb-0">Listing of collection on secondary marketplaces (Solsea, Solanart, Magic Eden).</p>
              </div>
              <span className="opacity-50 text-nowrap"><i className="fa-regular fa-circle"></i></span>
              </div>
            </a>
            <a href="/#rm-p1-1" className="list-group-item list-group-item-action d-flex gap-3 py-3" aria-current="true">
            {/* <img src="https://github.com/twbs.png" alt="twbs" width="32" height="32" className="rounded-circle flex-shrink-0 mt-1" /> */}
            <div className="d-flex gap-3 w-100 justify-content-between">
            <div>
            {/* <h6 className="mb-0">Another title here</h6> */}
            <p className="mb-0">Publication of project whitepaper describing a content-driven metaverse built on A-Frame.</p>
            </div>
            <small className="opacity-50 text-nowrap"><i className="fa-regular fa-circle"></i></small>
            </div>
            </a>
            </div>
                </div>
            </div>
          </div>
          {/* End of PHASE 1 */}      
          {/* Start of List Group : PHASE 2 */}
          <div className="tab-pane fade" id="rm-phase2" role="tabpanel" aria-labelledby="rm-phase2-link">
            <div className="col-auto">
            <div id="rm-p2-1" className="rm-content">
              <h2 className="rm-phase-heading p-1 px-3 mb-0 fs-1 shadow">Phase 2 <span className="fw-light" style={{color:"#f9f9f9"}}>_larva</span></h2>
              <div className="rm-title badge d-flex shadow"><h3 className="fs-4 my-auto">Development</h3></div>
              {/* <p className="description">Integer males uada tellus lorem, et condimentum neque commodo Integer males uada tellus lorem, et condimentum neque commodo</p> */}
              <div className="list-group list-group-flush mx-1 mx-md-2">
              <a href="/#rm-p2-1" className="list-group-item list-group-item-action d-flex gap-3 py-3" aria-current="true">
              {/* <img src="https://github.com/twbs.png" alt="twbs" width="32" height="32" className="rounded-circle flex-shrink-0 mt-1" /> */}
              <div className="d-flex gap-3 w-100 justify-content-between">
              <div>
              {/* <h6 className="mb-0">List group item heading</h6> */}
              <p className="mb-0">Series One mint complete; Minter accessible at metaball.world with 11,000 NFTs in Series Two.</p>
              </div>
              <span className="opacity-50 text-nowrap"><i className="fa-regular fa-circle"></i></span>
              </div>
              </a>
              <a href="/#rm-p2-1" className="list-group-item list-group-item-action list-group-item-success d-flex gap-3 py-3" aria-current="true">
              {/* <img src="https://github.com/twbs.png" alt="twbs" width="32" height="32" className="rounded-circle flex-shrink-0 mt-1" /> */}
              <div className="d-flex gap-3 w-100 justify-content-between">
              <div>
              {/* <h6 className="mb-0">Another title here</h6> */}
              <p className="mb-0">Development of gen-1 3D composites including Metaball avatar traits, object libraries, and environments.</p>
              </div>
              <span className="opacity-50 text-nowrap"><i className="fa-regular fa-circle"></i></span>
              </div>
              </a>
              <a href="/#rm-p2-1" className="list-group-item list-group-item-action list-group-item-success d-flex gap-3 py-3" aria-current="true">
              {/* <img src="https://github.com/twbs.png" alt="twbs" width="32" height="32" className="rounded-circle flex-shrink-0 mt-1" /> */}
              <div className="d-flex gap-3 w-100 justify-content-between">
              <div>
              {/* <h6 className="mb-0">Another title here</h6> */}
              <p className="mb-0">Community campaigns including contests, polls and airdrops promote engagement and drive growth.</p>
              </div>
              <span className="opacity-50 text-nowrap"><i className="fa-regular fa-circle"></i></span>
              </div>
              </a>
              <a href="/#rm-p2-1" className="list-group-item list-group-item-action list-group-item-success d-flex gap-3 py-3" aria-current="true">
              {/* <img src="https://github.com/twbs.png" alt="twbs" width="32" height="32" className="rounded-circle flex-shrink-0 mt-1" /> */}
              <div className="d-flex gap-3 w-100 justify-content-between">
              <div>
              {/* <h6 className="mb-0">Another title here</h6> */}
              <p className="mb-0">Expansion of core and support teams to meet project design and development needs.</p>
              </div>
              <small className="opacity-50 text-nowrap"><i className="fa-regular fa-circle"></i></small>
              </div>
              </a>
              </div>
            </div>
            </div>
          </div>
          {/* End of PHASE 2 */}
          {/* Start of PHASE 3 */}
          <div className="tab-pane fade" id="rm-phase3" role="tabpanel" aria-labelledby="rm-phase3-link">
            <div className="col-auto">
              <div id="rm-p3-1" className="rm-content">
                <h2 className="rm-phase-heading p-1 px-3 mb-0 fs-1 shadow">Phase 3 <span className="fw-light" style={{color:"#f9f9f9"}}>_pupa</span></h2>
                <div className="rm-title badge d-flex shadow"><h3 className="fs-4 my-auto">Pre-Launch</h3></div>
                {/* <p className="description">Integer males uada tellus lorem, et condimentum neque commodo Integer males uada tellus lorem, et condimentum neque commodo</p> */}
                <div className="list-group list-group-flush mx-1 mx-md-2">
                <a href="/#rm-p3-1" className="list-group-item list-group-item-action d-flex gap-3 py-3" aria-current="true">
                {/* <img src="https://github.com/twbs.png" alt="twbs" width="32" height="32" className="rounded-circle flex-shrink-0 mt-1" /> */}
                <div className="d-flex gap-3 w-100 justify-content-between">
                <div>
                {/* <h6 className="mb-0">List group item heading</h6> */}
                <p className="mb-0">Metaball NFT collection mint complete; Development of XtrOOd Minting Lab and Xonerator Builder commences.</p>
                </div>
                <span className="opacity-50 text-nowrap"><i className="fa-regular fa-circle"></i></span>
                </div>
                </a>
                <a href="/#rm-p3-1" className="list-group-item list-group-item-action list-group-item-primary d-flex gap-3 py-3" aria-current="true">
                {/* <img src="https://github.com/twbs.png" alt="twbs" width="32" height="32" className="rounded-circle flex-shrink-0 mt-1" /> */}
                <div className="d-flex gap-3 w-100 justify-content-between">
                <div>
                {/* <h6 className="mb-0">Another title here</h6> */}
                <p className="mb-0">Distribution of $XONE estate tokens to NFT holders. Limited access to the Xoner Dashboard at xone.land.</p>
                </div>
                <span className="opacity-50 text-nowrap"><i className="fa-regular fa-circle"></i></span>
                </div>
                </a>
                <a href="/#rm-p3-1" className="list-group-item list-group-item-action list-group-item-primary d-flex gap-3 py-3" aria-current="true">
                {/* <img src="https://github.com/twbs.png" alt="twbs" width="32" height="32" className="rounded-circle flex-shrink-0 mt-1" /> */}
                <div className="d-flex gap-3 w-100 justify-content-between">
                <div>
                {/* <h6 className="mb-0">Another title here</h6> */}
                <p className="mb-0">Strategic partnerships with Web3 platforms and services for performance, scalability and integrations.</p>
                </div>
                <span className="opacity-50 text-nowrap"><i className="fa-regular fa-circle"></i></span>
                </div>
                </a>
                <a href="/#rm-p3-1" className="list-group-item list-group-item-action list-group-item-primary d-flex gap-3 py-3" aria-current="true">
                {/* <img src="https://github.com/twbs.png" alt="twbs" width="32" height="32" className="rounded-circle flex-shrink-0 mt-1" /> */}
                <div className="d-flex gap-3 w-100 justify-content-between">
                <div>
                {/* <h6 className="mb-0">Another title here</h6> */}
                <p className="mb-0">$PLASMA token launch including liquidity pool, pre-sale and IDO in partnership with DEXLab.</p>
                </div>
                <small className="opacity-50 text-nowrap"><i className="fa-regular fa-circle"></i></small>
                </div>
                </a>
                </div>
              </div>
            </div>
          </div>
          {/* End of PHASE 3 */}
          {/* Start of PHASE 4 */}
          <div className="tab-pane fade" id="rm-phase4" role="tabpanel" aria-labelledby="rm-phase4-link">
            <div className="col-auto">
              <div id="rm-p4-1" className="rm-content">
                <h2 className="rm-phase-heading p-1 px-3 mb-0 fs-1 shadow">Phase 4 <span className="fw-light" style={{color:"#f9f9f9"}}>_eruca</span></h2>
                <div className="rm-title badge d-flex shadow"><h3 className="fs-4 my-auto">Closed Launch</h3></div>
                {/* <p className="description">Integer males uada tellus lorem, et condimentum neque commodo Integer males uada tellus lorem, et condimentum neque commodo</p> */}
                <div className="list-group mx-1 mx-md-2">
                <a href="/#rm-p4-1" className="list-group-item list-group-item-action list-group-item-danger d-flex gap-3 py-3" aria-current="true">
                {/* <img src="https://github.com/twbs.png" alt="twbs" width="32" height="32" className="rounded-circle flex-shrink-0 mt-1" /> */}
                <div className="d-flex gap-3 w-100 justify-content-between">
                <div>
                {/* <h6 className="mb-0">List group item heading</h6> */}
                <p className="mb-0">Launch of PlasmArt Marketplace - Xoners can now trade Plasmaverse NFTs and host custom creations.</p>
                </div>
                <span className="opacity-50 text-nowrap"><i className="fa-regular fa-circle"></i></span>
                </div>
                </a>
                <a href="/#rm-p4-1" className="list-group-item list-group-item-action list-group-item-danger d-flex gap-3 py-3" aria-current="true">
                {/* <img src="https://github.com/twbs.png" alt="twbs" width="32" height="32" className="rounded-circle flex-shrink-0 mt-1" /> */}
                <div className="d-flex gap-3 w-100 justify-content-between">
                <div>
                {/* <h6 className="mb-0">Another title here</h6> */}
                <p className="mb-0">Xonerator Beta release includes custom object composer and prebuilt object libraries.</p>
                </div>
                <span className="opacity-50 text-nowrap"><i className="fa-regular fa-circle"></i></span>
                </div>
                </a>
                <a href="/#rm-p4-1" className="list-group-item list-group-item-action list-group-item-danger d-flex gap-3 py-3" aria-current="true">
                {/* <img src="https://github.com/twbs.png" alt="twbs" width="32" height="32" className="rounded-circle flex-shrink-0 mt-1" /> */}
                <div className="d-flex gap-3 w-100 justify-content-between">
                <div>
                {/* <h6 className="mb-0">Another title here</h6> */}
                <p className="mb-0">$PLASMA token listing on major exchanges such as MEXC, Kucoin, Binance, and Crypto.com</p>
                </div>
                <span className="opacity-50 text-nowrap"><i className="fa-regular fa-circle"></i></span>
                </div>
                </a>
                <a href="/#rm-p4-1" className="list-group-item list-group-item-action list-group-item-danger d-flex gap-3 py-3" aria-current="true">
                {/* <img src="https://github.com/twbs.png" alt="twbs" width="32" height="32" className="rounded-circle flex-shrink-0 mt-1" /> */}
                <div className="d-flex gap-3 w-100 justify-content-between">
                <div>
                {/* <h6 className="mb-0">Another title here</h6> */}
                <p className="mb-0">Alpha Launch of XtrOOd Minting Lab - Xoners can mint new NFT variations and Xone assets.</p>
                </div>
                <small className="opacity-50 text-nowrap"><i className="fa-regular fa-circle"></i></small>
                </div>
                </a>
                </div>
              </div>
            </div>
          </div>
          {/* End of PHASE 4 */}
          {/* Start PHASE 5 */}
          <div className="tab-pane fade" id="rm-phase5" role="tabpanel" aria-labelledby="rm-phase5-link">
            <div className="col-auto">
              <div id="rm-p5-1" className="rm-content">
                <h2 className="rm-phase-heading p-1 px-3 mb-0 fs-1 shadow">Phase 5 <span className="fw-light" style={{color:"#f9f9f9"}}>_papilio</span></h2>
                <div className="rm-title badge d-flex shadow"><h3 className="fs-4 my-auto">Public Launch</h3></div>
                <div className="list-group mx-1 mx-md-2">
                <a href="/#rm-p5-1" className="list-group-item list-group-item-action list-group-item-secondary d-flex gap-3 py-3" aria-current="true">
                {/* <img src="https://github.com/twbs.png" alt="twbs" width="32" height="32" className="rounded-circle flex-shrink-0 mt-1" /> */}
                <div className="d-flex gap-3 w-100 justify-content-between">
                <div>
                {/* <h6 className="mb-0">List group item heading</h6> */}
                <p className="mb-0">The Plasmaverse (plasmaver.se) launches in limited Alpha. Xoners can explore in free-roam.</p>
                </div>
                <span className="opacity-50 text-nowrap"><i className="fa-regular fa-circle"></i></span>
                </div>
                </a>
                <a href="/#rm-p5-1" className="list-group-item list-group-item-action list-group-item-secondary d-flex gap-3 py-3" aria-current="true">
                {/* <img src="https://github.com/twbs.png" alt="twbs" width="32" height="32" className="rounded-circle flex-shrink-0 mt-1" /> */}
                <div className="d-flex gap-3 w-100 justify-content-between">
                <div>
                {/* <h6 className="mb-0">Another title here</h6> */}
                <p className="mb-0">Development and implementation of traffic graphing and Web3 monetization capabilities.</p>
                </div>
                <span className="opacity-50 text-nowrap"><i className="fa-regular fa-circle"></i></span>
                </div>
                </a>
                <a href="/#rm-p5-1" className="list-group-item list-group-item-action list-group-item-secondary d-flex gap-3 py-3" aria-current="true">
                {/* <img src="https://github.com/twbs.png" alt="twbs" width="32" height="32" className="rounded-circle flex-shrink-0 mt-1" /> */}
                <div className="d-flex gap-3 w-100 justify-content-between">
                <div>
                {/* <h6 className="mb-0">Another title here</h6> */}
                <p className="mb-0">New Xonerator components and integrations; distribution of $PLASMA launch-boost rewards.</p>
                </div>
                <span className="opacity-50 text-nowrap"><i className="fa-regular fa-circle"></i></span>
                </div>
                </a>
                <a href="/#rm-p5-1" className="list-group-item list-group-item-action list-group-item-secondary d-flex gap-3 py-3" aria-current="true">
                {/* <img src="https://github.com/twbs.png" alt="twbs" width="32" height="32" className="rounded-circle flex-shrink-0 mt-1" /> */}
                <div className="d-flex gap-3 w-100 justify-content-between">
                <div>
                {/* <h6 className="mb-0">Another title here</h6> */}
                <p className="mb-0">Ongoing community-led development of the Metaballix universe including Metaballs, the Plasmaverse, Xone Estates, Xonerator, XtrOOd, and PlasmArt.</p>
                </div>
                <small className="opacity-50 text-nowrap"><i className="fa-regular fa-circle"></i></small>
                </div>
                </a>
                </div>
                <p className="description">Ecosystems applications and environments are operational. Xone traffic monetization features go live at the end of this phase.</p>
              </div>
            </div>
          </div>
          {/* End of PHASE 5 */}
        </div>
      </div>
    </div>
  </div>
</div>

</section>
    {/* End of Roadmap Section */}
    
    {/* START OF FAQs SECTION */}
    <section id="faq-section" className="px-0" style={{marginTop: "-55px", marginBottom: "-182px"}}>
    <div className="container context-container rounded-3 p-1 p-md-2">
    
    <div className="row px-0 mx-0">
    <div className="col-12">
    <div id="faq" className="faqcard container card h-100 border-0 pt-5">
    <h1 className="pb-3 display-5 fw-bold lh-2 text-center text-white" style={{letterSpacing:"0.04em"}}>FAQs</h1>
    <div className="card-header w-100 mb-1">
      <div className="nav nav-tabs card-header-tabs nav-pills nav-fill noselect" id="nav-tab" role="tablist">
        <button className="nav-link mx-1 mb-1 active" id="faq-nfts-tab" data-bs-toggle="tab" data-bs-target="#faq-nfts" type="button" role="tab" aria-controls="faq-nfts" aria-selected="true">NFTs</button>
        <button className="nav-link mx-1 mb-1" id="faq-tokens-tab" data-bs-toggle="tab" data-bs-target="#faq-tokens" type="button" role="tab" aria-controls="faq-tokens" aria-selected="false">Tokens</button>
        <button className="nav-link mx-1 mb-1" id="faq-xones-tab" data-bs-toggle="tab" data-bs-target="#faq-xones" type="button" role="tab" aria-controls="faq-xones" aria-selected="false">Xones</button>
        <button className="nav-link mx-1 mb-1" id="faq-metaverse-tab" data-bs-toggle="tab" data-bs-target="#faq-metaverse" type="button" role="tab" aria-controls="faq-metaverse" aria-selected="false">Metaverse</button>
        <button className="nav-link mx-1 mb-1" id="faq-general-tab" data-bs-toggle="tab" data-bs-target="#faq-general" type="button" role="tab" aria-controls="faq-general" aria-selected="false">General</button>
      </div>
    </div>
    <div className="tab-content py-2 px-0 bg-transparent rounded-3" id="nav-tabContent">
    {/* FAQ Accordion 1 : NFTs */}
    <div className="tab-pane fade show active" id="faq-nfts" role="tabpanel" aria-labelledby="faq-nfts-tab">
    <div id="faq-nfts-qna" className="accordion w-100 text-start">
      <div className="accordion-item">
        <h2 className="accordion-header" id="faq-nfts-q1-h">
          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faq-nfts-q1" aria-controls="faq-nfts-q1" aria-expanded="true">
            How do I own a Metaball NFT?
          </button>
        </h2>
        <div id="faq-nfts-q1" className="accordion-collapse collapse show" aria-labelledby="faq-nfts-q1-h" data-bs-parent="#faq-nfts-qna">
          <div className="accordion-body">
            <div className="alert alert-info" role="alert">To mint on a <strong>desktop or laptop</strong>, install a <strong>browser-based wallet extension</strong> such as Phantom, Solflare, or Slope. Setup a new wallet (backup your seed phrase and private key securely) or import an existing one, top up your SOL, and connect to the Metaball Minter to begin minting.</div>
            <div className="alert alert-primary" role="alert">To mint on a <strong>mobile device</strong>, install a <strong>wallet app with a built-in web3 browser</strong> such as Phantom or Solflare. Setup a new wallet (backup your seed phrase and private key securely) or import an existing one, top up your SOL. From the browser tab (🌐), visit https://metaballix.com and connect to the Metaball Minter to begin minting.</div>
            <p>Kickstart your Plasmaverse journey by getting a hold of 1 of the 22,000 Metaball NFTs that shall float around in decentralized space between Phases 1 and 5 of the project.</p>
            <p>To own a Metaball NFT, you may 
              <ul>
                <li>use the Metaball Minter to mint during public sale events,</li>
                <li>use the Metaball Minter to mint with $MBALL during pre-launch and whitelist events, or</li>
                <li>buy Metaball NFTs on secondary marketplaces:</li>
                <ul>
                  <li>Magic Eden (TBA)</li>
                  <li>Solanart (TBA)</li>
                  <li>Solsea (TBA)</li>
                  <li>Metaplex (Pending)</li>
                  <li>PlasmArt (Phase 4)</li>
                </ul>
              </ul>
              Xoners (holders of Metaball NFTs who claim their Xone estates) will have the opportunity to unlock new traits and mint unique variations of Metaballs.
              </p>
              </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="faq-nfts-q2-h">
          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-nfts-q2" aria-controls="faq-nfts-q2" aria-expanded="false">
            What is the price of a Metaball NFT?
          </button>
        </h2>
        <div id="faq-nfts-q2" className="accordion-collapse collapse" aria-labelledby="faq-nfts-q2-h" data-bs-parent="#faq-nfts-qna">
          <div className="accordion-body">
          The fair launch price for each Metaball is <code>1.1 SOL</code>. The prices of each NFT on secondary markets will vary depending on the rarity, popularity and overall demand for your NFT in particular, and for the collection as a whole.
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="faq-nfts-q3-h">
          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-nfts-q3" aria-controls="faq-nfts-q3" aria-expanded="false">
          How is the rarity of each Metaball determined?
          </button>
        </h2>
        <div id="faq-nfts-q3" className="accordion-collapse collapse" aria-labelledby="faq-nfts-q3-h" data-bs-parent="#faq-nfts-qna">
          <div className="accordion-body">
          <p>The rarity of each Metaball NFT is based on its unique composition of attributes namely the Backdrop, Scene, Body, Face, and Head.</p>
          <p>A complete rarity table will be made available here when minting of the collection is complete.</p>
          </div>
        </div>
      </div>
    </div>
    </div>
    {/* FAQ Accordion 2 : Xones */}
    <div className="tab-pane fade" id="faq-xones" role="tabpanel" aria-labelledby="faq-xones-tab">
    <div id="faq-xones-qna" className="accordion w-100 text-start">
      <div className="accordion-item">
        <h2 className="accordion-header" id="faq-xones-q1-h">
          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faq-xones-q1" aria-controls="faq-xones-q1" aria-expanded="true">
            This section of the FAQs is...
          </button>
        </h2>
        <div id="faq-xones-q1" className="accordion-collapse collapse show" aria-labelledby="faq-xones-q1-h" data-bs-parent="#faq-xones-qna">
          <div className="accordion-body">
            <h4 className="text-center text-light p-3 my-2 mx-auto">Under Development...</h4>
          </div>
        </div>
      </div>
    </div>
    </div>
    {/* FAQ Accordion 3 : Tokens */}
    <div className="tab-pane fade" id="faq-tokens" role="tabpanel" aria-labelledby="faq-tokens-tab">
    <div id="faq-tokens-qna" className="accordion w-100 text-start">
      <div className="accordion-item">
        <h2 className="accordion-header" id="faq-tokens-q1-h">
          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faq-tokens-q1" aria-controls="faq-tokens-q1" aria-expanded="true">
            What are $MBALL tokens?
          </button>
        </h2>
        <div id="faq-tokens-q1" className="accordion-collapse collapse show" aria-labelledby="faq-tokens-q1-h" data-bs-parent="#faq-tokens-qna">
          <div className="accordion-body px-lg-5">
           <p>$MBALL (Metaball Tokens) is a fixed supply spl-token on the Solana network that serves to grant individual users access to the Metaball Minter while it is not live to the public (i.e. during pre-launch and whitelist phases).</p>
           <p>$MBALL will be used primarily for incentivization campaigns - users may receive $MBALL through affiliate compensation, plasmaverse earnings, achievements, airdrops, contests, and social media engagement.</p>
           {/* <div className="alert alert-light" role="alert">NOTE: when minting using $MBALL, the price of <code>1.1 SOL</code> is offset but transaction fees are still incurred.</div> */}
           <p>The maximum supply of <code>19,326,120 $MBALL</code> is at par with the total number of Metaball DNA variations that can be generated from the initial set of five attributes.</p>
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="faq-tokens-q2-h">
          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-tokens-q2" aria-controls="faq-tokens-q2" aria-expanded="false">
            What are $PLASMA tokens?
          </button>
        </h2>
        <div id="faq-tokens-q2" className="accordion-collapse collapse" aria-labelledby="faq-tokens-q2-h" data-bs-parent="#faq-tokens-qna">
          <div className="accordion-body px-lg-5">
          <p>The Metaballix token ($PLASMA) is the currency that will power the build-to-earn and monetization features of the anticipated Plasmaverse. Funds from the mint and trade of Metaball NFTs will be used to form an initial liquidity pool, followed by a pre-sale and IDO at the start of Phase 4.</p>
          <p>$PLASMA will also be used to inventivize platform participation and may be acquired through staking, affiliate rewards, airdrops, AR treasure hunts and More information on $PLASMA will be disclosed in a publication on ecosystem tokenomics (Phase 3).</p>
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="faq-tokens-q3-h">
          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-tokens-q3" aria-controls="faq-tokens-q3" aria-expanded="false">
            How do I get $MBALL / $XONE / $PLASMA?
          </button>
        </h2>
        <div id="faq-tokens-q3" className="accordion-collapse collapse" aria-labelledby="faq-tokens-q3-h" data-bs-parent="#faq-tokens-qna">
          <div className="accordion-body px-lg-5">
            <p>We expect to launch $MBALL tokens in Phase 1 so as to enable whitelist functionality within the ecosystem. Once active, all Metaball NFT holders will be eligible to receive $MBALL through community participation.</p>
            <p>$XONE tokens will be automatically distributed to Metaball NFT holders when Xone estates go live in Phase 3.</p>
            <p>We expect to launch the $PLASMA utility token in Phase 4 of our roadmap. Presale will be held on Dexlab DEX (trade.dexlab.space), followed by an anticipated listing on the Raydium AcceleRaytor program. More information on this will be made available in the upcoming whitepaper.</p>
          </div>
        </div>
      </div>
    </div>
    </div>
    {/* FAQ Accordion 4 : Metaverse */}
    <div className="tab-pane fade" id="faq-metaverse" role="tabpanel" aria-labelledby="faq-metaverse-tab">
    <div id="faq-metaverse-qna" className="accordion w-100 text-start">
      <div className="accordion-item">
        <h2 className="accordion-header" id="faq-metaverse-q1-h">
          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faq-metaverse-q1" aria-controls="faq-metaverse-q1" aria-expanded="true">
            This section of the FAQs is...
          </button>
        </h2>
        <div id="faq-metaverse-q1" className="accordion-collapse collapse show" aria-labelledby="faq-metaverse-q1-h" data-bs-parent="#faq-metaverse-qna">
          <div className="accordion-body">
          <h4 className="text-center text-light p-3 my-2 mx-auto">Under Development...</h4>
          </div>
        </div>
      </div>
    </div>

    </div>
    {/* FAQ Accordion 5 : Other-General */}
    <div className="tab-pane fade" id="faq-general" role="tabpanel" aria-labelledby="faq-general-tab">
    <div id="faq-general-qna" className="accordion w-100 text-left">
      <div className="accordion-item">
        <h2 className="accordion-header" id="faq-general-q1-h">
          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faq-general-q1" aria-controls="faq-general-q1" aria-expanded="true">
            What is an NFT?
          </button>
        </h2>
        <div id="faq-general-q1" className="accordion-collapse collapse show" aria-labelledby="faq-general-q1-h" data-bs-parent="#faq-general-qna">
          <div className="accordion-body">
            <p>An NFT (non-fungible token) constitutes a digital asset (such as artwork,  music, and videos) that possesses unique and non-interchangeable data which is used to identify it, interact with it, and ultimately establish proof of ownership. In other words, the technology used to verify the authenticity and ownership of each NFT is the same one used for verifying blockchain transactions on a large scale, such as on networks like Bitcoin and Ethereum.</p>
            <p>Cryptographic information is stored on a digital ledger using blockchain technology, while the digital assets linked to the NFT (the actual files) are often stored in a decentralized manner using services such as IPFS, Arweave and NFT.Storage. We use Arweave for file decentralization.</p>
            <div className="alert alert-light text-dark" role="alert">FUN FACT: An NFT on Solana is technically equivalent to a custom cryptocurrency token with a max supply of 1 unit.</div>    
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="faq-general-q2-h">
          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-general-q2" aria-controls="faq-general-q2" aria-expanded="false">
            What is a Candy Machine?
          </button>
        </h2>
        <div id="faq-general-q2" className="accordion-collapse collapse" aria-labelledby="faq-general-q2-h" data-bs-parent="#faq-general-qna">
          <div className="accordion-body">
          <p>A Candy Machine is an on-chain Solana program (or smart contract) developed by the Metaplex Foundation for the purpose of managing fair mints for large NFT collections like this one.</p>
          <p>The contract ensures that public sale starts and ends at the same time for everyone worldwide. It also ends the sale and ceases to accept any funds once it is out of NFTs to sell.</p>          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="faq-general-q3-h">
          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-general-q3" aria-controls="faq-general-q3" aria-expanded="false">
            Why are you launching the collection on May 29th?
          </button>
        </h2>
        <div id="faq-general-q3" className="accordion-collapse collapse" aria-labelledby="faq-general-q3-h" data-bs-parent="#faq-general-qna">
          <div className="accordion-body">
            <p>29-05-2022 is a numerically significant date for the Metaballix team - in base-9 (summing the digits), 29 is 11, and so is 5-2022.</p>
            <p>It is a notable date that holds a hint of austice with which we associate.</p>
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="faq-general-q4-h">
          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-general-q4" aria-controls="faq-general-q4" aria-expanded="false">
          I love your artwork! Can I use it my projects?
          </button>
        </h2>
        <div id="faq-general-q4" className="accordion-collapse collapse" aria-labelledby="faq-general-q4-h" data-bs-parent="#faq-general-qna">
          <div className="accordion-body">
            <p>Yes you may! Heri's base design vectors are hosted publicly and are currently available for download through Heri's portfolio pages:</p>
            <ul>
            <li>Vecteezy: https://www.vecteezy.com/members/heriyusuf</li>
            <li>Freepik: https://www.freepik.com/heriyusuf</li>
            <li>Shutterstock: https://www.shutterstock.com/g/heri+yusuf</li>
            </ul>
            <div className="alert alert-info" role="alert">
            <p>We ask that you add artist attribution for web, print or video. Insert this code as a caption or in the footer of your page:</p>
            <p><code><pre>&lt;a href="https://www.metaballix.com/#heri" target="_blank" rel="noreferrer"&gt;(Attribution: Heri Yusuf Art)&lt;/a&gt;</pre></code></p>
            </div>
            <p>Thank you for appreciating the work :)</p>
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    
    </section>
    {/* END of FAQs Section */}

    
    {/* mbp Profile Cards - Social SVGs */}
    <svg visibility="hidden">
      <defs>
        <symbol id="icon-codepen" viewBox="0 0 32 32">
          <title>codepen</title>
          <path d="M31.872 10.912v-0.032c0-0.064 0-0.064 0-0.096v-0.064c0-0.064 0-0.064-0.064-0.096 0 0 0-0.064-0.064-0.064 0-0.064-0.064-0.064-0.064-0.096 0 0 0-0.064-0.064-0.064 0-0.064-0.064-0.064-0.064-0.096l-0.192-0.192v-0.064l-0.064-0.064-14.592-9.696c-0.448-0.32-1.056-0.32-1.536 0l-14.528 9.696-0.32 0.32c0 0-0.064 0.064-0.064 0.096 0 0 0 0.064-0.064 0.064 0 0.064-0.064 0.064-0.064 0.096 0 0 0 0.064-0.064 0.064 0 0.064 0 0.064-0.064 0.096v0.064c0 0.064 0 0.064 0 0.096v0.064c0 0.064 0 0.096 0 0.16v9.696c0 0.064 0 0.096 0 0.16v0.064c0 0.064 0 0.064 0 0.096v0.064c0 0.064 0 0.064 0.064 0.096 0 0 0 0.064 0.064 0.064 0 0.064 0.064 0.064 0.064 0.096 0 0 0 0.064 0.064 0.064 0 0.064 0.064 0.064 0.064 0.096l0.256 0.256 0.064 0.032 14.528 9.728c0.224 0.16 0.48 0.224 0.768 0.224s0.544-0.064 0.768-0.224l14.528-9.728 0.32-0.32c0 0 0.064-0.064 0.064-0.096 0 0 0-0.064 0.064-0.064 0-0.064 0.064-0.064 0.064-0.096 0 0 0-0.064 0.064-0.064 0-0.064 0-0.064 0.064-0.096v-0.032c0-0.064 0-0.064 0-0.096v-0.064c0-0.064 0-0.096 0-0.16v-9.664c0-0.064 0-0.096 0-0.224zM17.312 4l10.688 7.136-4.768 3.168-5.92-3.936v-6.368zM14.56 4v6.368l-5.92 3.968-4.768-3.168 10.688-7.168zM2.784 13.664l3.392 2.304-3.392 2.304c0 0 0-4.608 0-4.608zM14.56 28l-10.688-7.136 4.768-3.2 5.92 3.936v6.4zM15.936 19.2l-4.832-3.232 4.832-3.232 4.832 3.232-4.832 3.232zM17.312 28v-6.432l5.92-3.936 4.8 3.168-10.72 7.2zM29.12 18.272l-3.392-2.304 3.392-2.304v4.608z"></path>
        </symbol>
    
        <symbol id="icon-github" viewBox="0 0 32 32">
          <title>github</title>
          <path d="M16.192 0.512c-8.832 0-16 7.168-16 16 0 7.072 4.576 13.056 10.944 15.168 0.8 0.16 1.088-0.352 1.088-0.768 0-0.384 0-1.632-0.032-2.976-4.448 0.96-5.376-1.888-5.376-1.888-0.736-1.856-1.792-2.336-1.792-2.336-1.44-0.992 0.096-0.96 0.096-0.96 1.6 0.128 2.464 1.664 2.464 1.664 1.44 2.432 3.744 1.728 4.672 1.344 0.128-1.024 0.544-1.728 1.024-2.144-3.552-0.448-7.296-1.824-7.296-7.936 0-1.76 0.64-3.168 1.664-4.288-0.16-0.416-0.704-2.016 0.16-4.224 0 0 1.344-0.416 4.416 1.632 1.28-0.352 2.656-0.544 4-0.544s2.72 0.192 4 0.544c3.040-2.080 4.384-1.632 4.384-1.632 0.864 2.208 0.32 3.84 0.16 4.224 1.024 1.12 1.632 2.56 1.632 4.288 0 6.144-3.744 7.488-7.296 7.904 0.576 0.512 1.088 1.472 1.088 2.976 0 2.144-0.032 3.872-0.032 4.384 0 0.416 0.288 0.928 1.088 0.768 6.368-2.112 10.944-8.128 10.944-15.168 0-8.896-7.168-16.032-16-16.032z"></path>
          <path d="M6.24 23.488c-0.032 0.064-0.16 0.096-0.288 0.064-0.128-0.064-0.192-0.16-0.128-0.256 0.032-0.096 0.16-0.096 0.288-0.064 0.128 0.064 0.192 0.16 0.128 0.256v0z"></path>
          <path d="M6.912 24.192c-0.064 0.064-0.224 0.032-0.32-0.064s-0.128-0.256-0.032-0.32c0.064-0.064 0.224-0.032 0.32 0.064s0.096 0.256 0.032 0.32v0z"></path>
          <path d="M7.52 25.12c-0.096 0.064-0.256 0-0.352-0.128s-0.096-0.32 0-0.384c0.096-0.064 0.256 0 0.352 0.128 0.128 0.128 0.128 0.32 0 0.384v0z"></path>
          <path d="M8.384 26.016c-0.096 0.096-0.288 0.064-0.416-0.064s-0.192-0.32-0.096-0.416c0.096-0.096 0.288-0.064 0.416 0.064 0.16 0.128 0.192 0.32 0.096 0.416v0z"></path>
          <path d="M9.6 26.528c-0.032 0.128-0.224 0.192-0.384 0.128-0.192-0.064-0.288-0.192-0.256-0.32s0.224-0.192 0.416-0.128c0.128 0.032 0.256 0.192 0.224 0.32v0z"></path>
          <path d="M10.912 26.624c0 0.128-0.16 0.256-0.352 0.256s-0.352-0.096-0.352-0.224c0-0.128 0.16-0.256 0.352-0.256 0.192-0.032 0.352 0.096 0.352 0.224v0z"></path>
          <path d="M12.128 26.4c0.032 0.128-0.096 0.256-0.288 0.288s-0.352-0.032-0.384-0.16c-0.032-0.128 0.096-0.256 0.288-0.288s0.352 0.032 0.384 0.16v0z"></path>
        </symbol>
    
        <symbol id="icon-location" viewBox="0 0 32 32">
          <title>location</title>
          <path d="M16 31.68c-0.352 0-0.672-0.064-1.024-0.16-0.8-0.256-1.44-0.832-1.824-1.6l-6.784-13.632c-1.664-3.36-1.568-7.328 0.32-10.592 1.856-3.2 4.992-5.152 8.608-5.376h1.376c3.648 0.224 6.752 2.176 8.608 5.376 1.888 3.264 2.016 7.232 0.352 10.592l-6.816 13.664c-0.288 0.608-0.8 1.12-1.408 1.408-0.448 0.224-0.928 0.32-1.408 0.32zM15.392 2.368c-2.88 0.192-5.408 1.76-6.912 4.352-1.536 2.688-1.632 5.92-0.288 8.672l6.816 13.632c0.128 0.256 0.352 0.448 0.64 0.544s0.576 0.064 0.832-0.064c0.224-0.096 0.384-0.288 0.48-0.48l6.816-13.664c1.376-2.752 1.248-5.984-0.288-8.672-1.472-2.56-4-4.128-6.88-4.32h-1.216zM16 17.888c-3.264 0-5.92-2.656-5.92-5.92 0-3.232 2.656-5.888 5.92-5.888s5.92 2.656 5.92 5.92c0 3.264-2.656 5.888-5.92 5.888zM16 8.128c-2.144 0-3.872 1.728-3.872 3.872s1.728 3.872 3.872 3.872 3.872-1.728 3.872-3.872c0-2.144-1.76-3.872-3.872-3.872z"></path>
          <path d="M16 32c-0.384 0-0.736-0.064-1.12-0.192-0.864-0.288-1.568-0.928-1.984-1.728l-6.784-13.664c-1.728-3.456-1.6-7.52 0.352-10.912 1.888-3.264 5.088-5.28 8.832-5.504h1.376c3.744 0.224 6.976 2.24 8.864 5.536 1.952 3.36 2.080 7.424 0.352 10.912l-6.784 13.632c-0.32 0.672-0.896 1.216-1.568 1.568-0.48 0.224-0.992 0.352-1.536 0.352zM15.36 0.64h-0.064c-3.488 0.224-6.56 2.112-8.32 5.216-1.824 3.168-1.952 7.040-0.32 10.304l6.816 13.632c0.32 0.672 0.928 1.184 1.632 1.44s1.472 0.192 2.176-0.16c0.544-0.288 1.024-0.736 1.28-1.28l6.816-13.632c1.632-3.264 1.504-7.136-0.32-10.304-1.824-3.104-4.864-5.024-8.384-5.216h-1.312zM16 29.952c-0.16 0-0.32-0.032-0.448-0.064-0.352-0.128-0.64-0.384-0.8-0.704l-6.816-13.664c-1.408-2.848-1.312-6.176 0.288-8.96 1.536-2.656 4.16-4.32 7.168-4.512h1.216c3.040 0.192 5.632 1.824 7.2 4.512 1.6 2.752 1.696 6.112 0.288 8.96l-6.848 13.632c-0.128 0.288-0.352 0.512-0.64 0.64-0.192 0.096-0.384 0.16-0.608 0.16zM15.424 2.688c-2.784 0.192-5.216 1.696-6.656 4.192-1.504 2.592-1.6 5.696-0.256 8.352l6.816 13.632c0.096 0.192 0.256 0.32 0.448 0.384s0.416 0.064 0.608-0.032c0.16-0.064 0.288-0.192 0.352-0.352l6.816-13.664c1.312-2.656 1.216-5.792-0.288-8.352-1.472-2.464-3.904-4-6.688-4.16h-1.152zM16 18.208c-3.424 0-6.24-2.784-6.24-6.24 0-3.424 2.816-6.208 6.24-6.208s6.24 2.784 6.24 6.24c0 3.424-2.816 6.208-6.24 6.208zM16 6.4c-3.072 0-5.6 2.496-5.6 5.6 0 3.072 2.528 5.6 5.6 5.6s5.6-2.496 5.6-5.6c0-3.104-2.528-5.6-5.6-5.6zM16 16.16c-2.304 0-4.16-1.888-4.16-4.16s1.888-4.16 4.16-4.16c2.304 0 4.16 1.888 4.16 4.16s-1.856 4.16-4.16 4.16zM16 8.448c-1.952 0-3.552 1.6-3.552 3.552s1.6 3.552 3.552 3.552c1.952 0 3.552-1.6 3.552-3.552s-1.6-3.552-3.552-3.552z"></path>
        </symbol>
    
        <symbol id="icon-facebook" viewBox="0 0 32 32">
          <title>facebook</title>
          <path d="M19 6h5v-6h-5c-3.86 0-7 3.14-7 7v3h-4v6h4v16h6v-16h5l1-6h-6v-3c0-0.542 0.458-1 1-1z"></path>
        </symbol>
    
        <symbol id="icon-instagram" viewBox="0 0 32 32">
          <title>instagram</title>
          <path d="M16 2.881c4.275 0 4.781 0.019 6.462 0.094 1.563 0.069 2.406 0.331 2.969 0.55 0.744 0.288 1.281 0.638 1.837 1.194 0.563 0.563 0.906 1.094 1.2 1.838 0.219 0.563 0.481 1.412 0.55 2.969 0.075 1.688 0.094 2.194 0.094 6.463s-0.019 4.781-0.094 6.463c-0.069 1.563-0.331 2.406-0.55 2.969-0.288 0.744-0.637 1.281-1.194 1.837-0.563 0.563-1.094 0.906-1.837 1.2-0.563 0.219-1.413 0.481-2.969 0.55-1.688 0.075-2.194 0.094-6.463 0.094s-4.781-0.019-6.463-0.094c-1.563-0.069-2.406-0.331-2.969-0.55-0.744-0.288-1.281-0.637-1.838-1.194-0.563-0.563-0.906-1.094-1.2-1.837-0.219-0.563-0.481-1.413-0.55-2.969-0.075-1.688-0.094-2.194-0.094-6.463s0.019-4.781 0.094-6.463c0.069-1.563 0.331-2.406 0.55-2.969 0.288-0.744 0.638-1.281 1.194-1.838 0.563-0.563 1.094-0.906 1.838-1.2 0.563-0.219 1.412-0.481 2.969-0.55 1.681-0.075 2.188-0.094 6.463-0.094zM16 0c-4.344 0-4.887 0.019-6.594 0.094-1.7 0.075-2.869 0.35-3.881 0.744-1.056 0.412-1.95 0.956-2.837 1.85-0.894 0.888-1.438 1.781-1.85 2.831-0.394 1.019-0.669 2.181-0.744 3.881-0.075 1.713-0.094 2.256-0.094 6.6s0.019 4.887 0.094 6.594c0.075 1.7 0.35 2.869 0.744 3.881 0.413 1.056 0.956 1.95 1.85 2.837 0.887 0.887 1.781 1.438 2.831 1.844 1.019 0.394 2.181 0.669 3.881 0.744 1.706 0.075 2.25 0.094 6.594 0.094s4.888-0.019 6.594-0.094c1.7-0.075 2.869-0.35 3.881-0.744 1.050-0.406 1.944-0.956 2.831-1.844s1.438-1.781 1.844-2.831c0.394-1.019 0.669-2.181 0.744-3.881 0.075-1.706 0.094-2.25 0.094-6.594s-0.019-4.887-0.094-6.594c-0.075-1.7-0.35-2.869-0.744-3.881-0.394-1.063-0.938-1.956-1.831-2.844-0.887-0.887-1.781-1.438-2.831-1.844-1.019-0.394-2.181-0.669-3.881-0.744-1.712-0.081-2.256-0.1-6.6-0.1v0z"></path>
          <path d="M16 7.781c-4.537 0-8.219 3.681-8.219 8.219s3.681 8.219 8.219 8.219 8.219-3.681 8.219-8.219c0-4.537-3.681-8.219-8.219-8.219zM16 21.331c-2.944 0-5.331-2.387-5.331-5.331s2.387-5.331 5.331-5.331c2.944 0 5.331 2.387 5.331 5.331s-2.387 5.331-5.331 5.331z"></path>
          <path d="M26.462 7.456c0 1.060-0.859 1.919-1.919 1.919s-1.919-0.859-1.919-1.919c0-1.060 0.859-1.919 1.919-1.919s1.919 0.859 1.919 1.919z"></path>
        </symbol>
    
        <symbol id="icon-twitter" viewBox="0 0 32 32">
          <title>twitter</title>
          <path d="M32 7.075c-1.175 0.525-2.444 0.875-3.769 1.031 1.356-0.813 2.394-2.1 2.887-3.631-1.269 0.75-2.675 1.3-4.169 1.594-1.2-1.275-2.906-2.069-4.794-2.069-3.625 0-6.563 2.938-6.563 6.563 0 0.512 0.056 1.012 0.169 1.494-5.456-0.275-10.294-2.888-13.531-6.862-0.563 0.969-0.887 2.1-0.887 3.3 0 2.275 1.156 4.287 2.919 5.463-1.075-0.031-2.087-0.331-2.975-0.819 0 0.025 0 0.056 0 0.081 0 3.181 2.263 5.838 5.269 6.437-0.55 0.15-1.131 0.231-1.731 0.231-0.425 0-0.831-0.044-1.237-0.119 0.838 2.606 3.263 4.506 6.131 4.563-2.25 1.762-5.075 2.813-8.156 2.813-0.531 0-1.050-0.031-1.569-0.094 2.913 1.869 6.362 2.95 10.069 2.95 12.075 0 18.681-10.006 18.681-18.681 0-0.287-0.006-0.569-0.019-0.85 1.281-0.919 2.394-2.075 3.275-3.394z"></path>
        </symbol>
    
        <symbol id="icon-behance" viewBox="0 0 32 32">
          <title>behance</title>
          <path d="M9.281 6.412c0.944 0 1.794 0.081 2.569 0.25 0.775 0.162 1.431 0.438 1.988 0.813 0.55 0.375 0.975 0.875 1.287 1.5 0.3 0.619 0.45 1.394 0.45 2.313 0 0.994-0.225 1.819-0.675 2.481-0.456 0.662-1.119 1.2-2.006 1.625 1.213 0.35 2.106 0.962 2.706 1.831 0.6 0.875 0.887 1.925 0.887 3.163 0 1-0.194 1.856-0.575 2.581-0.387 0.731-0.912 1.325-1.556 1.781-0.65 0.462-1.4 0.8-2.237 1.019-0.831 0.219-1.688 0.331-2.575 0.331h-9.544v-19.688h9.281zM8.719 14.363c0.769 0 1.406-0.181 1.906-0.55 0.5-0.363 0.738-0.963 0.738-1.787 0-0.456-0.081-0.838-0.244-1.131-0.169-0.294-0.387-0.525-0.669-0.688-0.275-0.169-0.588-0.281-0.956-0.344-0.356-0.069-0.731-0.1-1.113-0.1h-4.050v4.6h4.388zM8.956 22.744c0.425 0 0.831-0.038 1.213-0.125 0.387-0.087 0.731-0.219 1.019-0.419 0.287-0.194 0.531-0.45 0.706-0.788 0.175-0.331 0.256-0.756 0.256-1.275 0-1.012-0.287-1.738-0.856-2.175-0.569-0.431-1.325-0.644-2.262-0.644h-4.7v5.419h4.625z"></path>
          <path d="M22.663 22.675c0.587 0.575 1.431 0.863 2.531 0.863 0.788 0 1.475-0.2 2.044-0.6s0.913-0.825 1.044-1.262h3.45c-0.556 1.719-1.394 2.938-2.544 3.675-1.131 0.738-2.519 1.113-4.125 1.113-1.125 0-2.131-0.181-3.038-0.538-0.906-0.363-1.663-0.869-2.3-1.531-0.619-0.663-1.106-1.45-1.45-2.375-0.337-0.919-0.512-1.938-0.512-3.038 0-1.069 0.175-2.063 0.525-2.981 0.356-0.925 0.844-1.719 1.494-2.387s1.413-1.2 2.313-1.588c0.894-0.387 1.881-0.581 2.975-0.581 1.206 0 2.262 0.231 3.169 0.706 0.9 0.469 1.644 1.1 2.225 1.887s0.994 1.694 1.25 2.706c0.256 1.012 0.344 2.069 0.275 3.175h-10.294c0 1.119 0.375 2.188 0.969 2.756zM27.156 15.188c-0.462-0.512-1.256-0.794-2.212-0.794-0.625 0-1.144 0.106-1.556 0.319-0.406 0.213-0.738 0.475-0.994 0.787-0.25 0.313-0.425 0.65-0.525 1.006-0.1 0.344-0.163 0.663-0.181 0.938h6.375c-0.094-1-0.438-1.738-0.906-2.256z"></path>
          <path d="M20.887 8h7.981v1.944h-7.981v-1.944z"></path>
        </symbol>
    
        <symbol id="icon-link" viewBox="0 0 32 32">
          <title>link</title>
          <path d="M17.984 11.456c-0.704 0.704-0.704 1.856 0 2.56 2.112 2.112 2.112 5.568 0 7.68l-5.12 5.12c-2.048 2.048-5.632 2.048-7.68 0-1.024-1.024-1.6-2.4-1.6-3.84s0.576-2.816 1.6-3.84c0.704-0.704 0.704-1.856 0-2.56s-1.856-0.704-2.56 0c-1.696 1.696-2.624 3.968-2.624 6.368 0 2.432 0.928 4.672 2.656 6.4 1.696 1.696 3.968 2.656 6.4 2.656s4.672-0.928 6.4-2.656l5.12-5.12c3.52-3.52 3.52-9.248 0-12.8-0.736-0.672-1.888-0.672-2.592 0.032z"></path>
          <path d="M29.344 2.656c-1.696-1.728-3.968-2.656-6.4-2.656s-4.672 0.928-6.4 2.656l-5.12 5.12c-3.52 3.52-3.52 9.248 0 12.8 0.352 0.352 0.8 0.544 1.28 0.544s0.928-0.192 1.28-0.544c0.704-0.704 0.704-1.856 0-2.56-2.112-2.112-2.112-5.568 0-7.68l5.12-5.12c2.048-2.048 5.632-2.048 7.68 0 1.024 1.024 1.6 2.4 1.6 3.84s-0.576 2.816-1.6 3.84c-0.704 0.704-0.704 1.856 0 2.56s1.856 0.704 2.56 0c1.696-1.696 2.656-3.968 2.656-6.4s-0.928-4.704-2.656-6.4z"></path>
        </symbol>
      </defs>
    </svg>


    <Particles id="tsparticles" options={{
            background: { color: "none", },
            fullScreen: { enable: true, zIndex: -1, },
            fpsLimit: 60,
            interactivity: {
              events: {
                resize: true,
                onClick: { enable: true, mode: "push", },
                onHover: {
                  enable: false,
                  mode: "repulse",
                  parallax: { enable: false, force: 50, smooth: 10, },
                },
              },
              modes: {
                push: { quantity: 2, },
                repulse: { distance: 50, duration: 0.7, },
                bubble: {
                  distance: 400,
                  duration: 2,
                  opacity: 0.8,
                  size: 40,
                },
              },
            },
            particles: {
              color: { value: "#ffffff", },
              collisions: { enable: true, },
              move: {
                direction: "none",
                enable: true,
                outModes: "bounce",
                random: false,
                speed: 1,
                straight: false,
                path: {},
                spin: {},
              },
              number: {
                density: { enable: true, area: 1100, },
                value: 100,
              },
              opacity: {
                random: true,
                value: { min: 0.2, max: 0.45, },
                animation: {
                  enable: true,
                  speed: 1,
                  sync: false,
                  startValue: "random",
                  count: 1,
                  destroy: "none",
                },
              },
              shape: { type: "circle", },
              size: { 
                random: true,
                value: { min: 1, max: 7, },
                animation: { enable: true, speed: 10, minimumValue: 2, },
              },
            },
            detectRetina: true,
          }}
        />
    
    {/* <div className="bg-overlay"> </div> */}
    
{/* Figma Slider */}
  <div aria-hidden="true" className="mslide-1" style={{display:"none"}}>
    <div className="metaballix-sl1d3r">
      Metaballs
      <svg fill="none" viewBox="0 0 77 52" className="metaballix-sl1d3"><path fill="#FF8577" stroke="#000" strokeWidth="2" d="M1 51V26C1 9 9 1 22 1c17 0 25 17 37 17 9 0 13-4 13-17h4v25c0 17-8 25-21 25-16 0-25-17-37-17-8 0-13 5-13 17H1z"></path></svg>
      Plasmaverse
      <svg fill="none" viewBox="0 0 60 60" className="metaballix-sl1d3"><path fill="#FF8577" stroke="#000" strokeWidth="2" d="M23.93 32.75h-7.05a15.88 15.88 0 1115.87-15.88v7.05a3.33 3.33 0 003.33 3.33h7.05a15.88 15.88 0 11-15.88 15.88v-7.05a3.33 3.33 0 00-3.32-3.33z"></path></svg>
      Xones
      <svg fill="none" viewBox="0 0 67 50" className="metaballix-sl1d3"><mask id="a" fill="#fff"><path d="M12.5 0H0v50h12.5c8.7 0 16.35-4.44 20.83-11.18A24.98 24.98 0 0054.17 50h12.5V0h-12.5c-8.7 0-16.36 4.44-20.84 11.18A24.98 24.98 0 0012.5 0z"></path></mask><path fill="#FF8577" d="M12.5 0H0v50h12.5c8.7 0 16.35-4.44 20.83-11.18A24.98 24.98 0 0054.17 50h12.5V0h-12.5c-8.7 0-16.36 4.44-20.84 11.18A24.98 24.98 0 0012.5 0z"></path><path fill="#000" d="M0 0v-2h-2v2h2zm0 50h-2v2h2v-2zm33.33-11.18l1.67-1.1-1.67-2.5-1.66 2.5 1.66 1.1zM66.67 50v2h2v-2h-2zm0-50h2v-2h-2v2zm-12.5 0v-2 2zM33.33 11.18l-1.66 1.1 1.66 2.5 1.67-2.5-1.67-1.1zM0 2h12.5v-4H0v4zm2 48V0h-4v50h4zm10.5-2H0v4h12.5v-4zm19.17-10.28A22.98 22.98 0 0112.5 48v4c9.4 0 17.66-4.8 22.5-12.07l-3.33-2.21zM54.17 48c-8 0-15.05-4.08-19.17-10.28l-3.33 2.21A26.98 26.98 0 0054.17 52v-4zm12.5 0h-12.5v4h12.5v-4zm-2-48v50h4V0h-4zm-10.5 2h12.5v-4h-12.5v4zM35 12.28A22.98 22.98 0 0154.17 2v-4c-9.4 0-17.67 4.8-22.5 12.07L35 12.28zM12.5 2c8 0 15.04 4.08 19.17 10.28L35 10.07A26.98 26.98 0 0012.5-2v4z" mask="url(#a)"></path></svg>
      A-Frame
      <svg fill="none" viewBox="0 0 63 62" className="metaballix-sl1d3"><path fill="#FF8577" stroke="#000" strokeWidth="2" d="M49.87 2.4L8.32 32.13a15.92 15.92 0 1022.21 22.22L60.3 12.83A7.47 7.47 0 0049.87 2.4z"></path></svg>
      WebXR
      <svg fill="none" viewBox="0 0 63 56" className="metaballix-sl1d3"><path fill="#FF8577" stroke="#000" strokeWidth="2" d="M1.7 1.8v52.5l18.7-9.6 11.3 9.5 11.2-9.5 18.8 9.5V1.8l-18.8 10-11.2-10-11.3 10-18.7-10z"></path></svg>
      Three.js
      <svg fill="none" viewBox="0 0 77 52" className="metaballix-sl1d3"><path fill="#FF8577" stroke="#000" strokeWidth="2" d="M1 51V26C1 9 9 1 22 1c17 0 25 17 37 17 9 0 13-4 13-17h4v25c0 17-8 25-21 25-16 0-25-17-37-17-8 0-13 5-13 17H1z"></path></svg>
      Solana
      <svg fill="none" viewBox="0 0 60 60" className="metaballix-sl1d3"><path fill="#FF8577" stroke="#000" strokeWidth="2" d="M23.93 32.75h-7.05a15.88 15.88 0 1115.87-15.88v7.05a3.33 3.33 0 003.33 3.33h7.05a15.88 15.88 0 11-15.88 15.88v-7.05a3.33 3.33 0 00-3.32-3.33z"></path></svg>
      Metaplex
      <svg fill="none" viewBox="0 0 67 50" className="metaballix-sl1d3"><mask id="a" fill="#fff"><path d="M12.5 0H0v50h12.5c8.7 0 16.35-4.44 20.83-11.18A24.98 24.98 0 0054.17 50h12.5V0h-12.5c-8.7 0-16.36 4.44-20.84 11.18A24.98 24.98 0 0012.5 0z"></path></mask><path fill="#FF8577" d="M12.5 0H0v50h12.5c8.7 0 16.35-4.44 20.83-11.18A24.98 24.98 0 0054.17 50h12.5V0h-12.5c-8.7 0-16.36 4.44-20.84 11.18A24.98 24.98 0 0012.5 0z"></path><path fill="#000" d="M0 0v-2h-2v2h2zm0 50h-2v2h2v-2zm33.33-11.18l1.67-1.1-1.67-2.5-1.66 2.5 1.66 1.1zM66.67 50v2h2v-2h-2zm0-50h2v-2h-2v2zm-12.5 0v-2 2zM33.33 11.18l-1.66 1.1 1.66 2.5 1.67-2.5-1.67-1.1zM0 2h12.5v-4H0v4zm2 48V0h-4v50h4zm10.5-2H0v4h12.5v-4zm19.17-10.28A22.98 22.98 0 0112.5 48v4c9.4 0 17.66-4.8 22.5-12.07l-3.33-2.21zM54.17 48c-8 0-15.05-4.08-19.17-10.28l-3.33 2.21A26.98 26.98 0 0054.17 52v-4zm12.5 0h-12.5v4h12.5v-4zm-2-48v50h4V0h-4zm-10.5 2h12.5v-4h-12.5v4zM35 12.28A22.98 22.98 0 0154.17 2v-4c-9.4 0-17.67 4.8-22.5 12.07L35 12.28zM12.5 2c8 0 15.04 4.08 19.17 10.28L35 10.07A26.98 26.98 0 0012.5-2v4z" mask="url(#a)"></path></svg>
      Arweave
      <svg fill="none" viewBox="0 0 63 62" className="metaballix-sl1d3"><path fill="#FF8577" stroke="#000" strokeWidth="2" d="M49.87 2.4L8.32 32.13a15.92 15.92 0 1022.21 22.22L60.3 12.83A7.47 7.47 0 0049.87 2.4z"></path></svg>
      Ankr
      <svg fill="none" viewBox="0 0 63 56" className="metaballix-sl1d3"><path fill="#FF8577" stroke="#000" strokeWidth="2" d="M1.7 1.8v52.5l18.7-9.6 11.3 9.5 11.2-9.5 18.8 9.5V1.8l-18.8 10-11.2-10-11.3 10-18.7-10z"></path></svg>  
    </div>
    <div className="metaballix-sl1d3r">
      Metaballs
      <svg fill="none" viewBox="0 0 77 52" className="metaballix-sl1d3"><path fill="#FF8577" stroke="#000" strokeWidth="2" d="M1 51V26C1 9 9 1 22 1c17 0 25 17 37 17 9 0 13-4 13-17h4v25c0 17-8 25-21 25-16 0-25-17-37-17-8 0-13 5-13 17H1z"></path></svg>
      Plasmaverse
      <svg fill="none" viewBox="0 0 60 60" className="metaballix-sl1d3"><path fill="#FF8577" stroke="#000" strokeWidth="2" d="M23.93 32.75h-7.05a15.88 15.88 0 1115.87-15.88v7.05a3.33 3.33 0 003.33 3.33h7.05a15.88 15.88 0 11-15.88 15.88v-7.05a3.33 3.33 0 00-3.32-3.33z"></path></svg>
      Xones
      <svg fill="none" viewBox="0 0 67 50" className="metaballix-sl1d3"><mask id="a" fill="#fff"><path d="M12.5 0H0v50h12.5c8.7 0 16.35-4.44 20.83-11.18A24.98 24.98 0 0054.17 50h12.5V0h-12.5c-8.7 0-16.36 4.44-20.84 11.18A24.98 24.98 0 0012.5 0z"></path></mask><path fill="#FF8577" d="M12.5 0H0v50h12.5c8.7 0 16.35-4.44 20.83-11.18A24.98 24.98 0 0054.17 50h12.5V0h-12.5c-8.7 0-16.36 4.44-20.84 11.18A24.98 24.98 0 0012.5 0z"></path><path fill="#000" d="M0 0v-2h-2v2h2zm0 50h-2v2h2v-2zm33.33-11.18l1.67-1.1-1.67-2.5-1.66 2.5 1.66 1.1zM66.67 50v2h2v-2h-2zm0-50h2v-2h-2v2zm-12.5 0v-2 2zM33.33 11.18l-1.66 1.1 1.66 2.5 1.67-2.5-1.67-1.1zM0 2h12.5v-4H0v4zm2 48V0h-4v50h4zm10.5-2H0v4h12.5v-4zm19.17-10.28A22.98 22.98 0 0112.5 48v4c9.4 0 17.66-4.8 22.5-12.07l-3.33-2.21zM54.17 48c-8 0-15.05-4.08-19.17-10.28l-3.33 2.21A26.98 26.98 0 0054.17 52v-4zm12.5 0h-12.5v4h12.5v-4zm-2-48v50h4V0h-4zm-10.5 2h12.5v-4h-12.5v4zM35 12.28A22.98 22.98 0 0154.17 2v-4c-9.4 0-17.67 4.8-22.5 12.07L35 12.28zM12.5 2c8 0 15.04 4.08 19.17 10.28L35 10.07A26.98 26.98 0 0012.5-2v4z" mask="url(#a)"></path></svg>
      A-Frame
      <svg fill="none" viewBox="0 0 63 62" className="metaballix-sl1d3"><path fill="#FF8577" stroke="#000" strokeWidth="2" d="M49.87 2.4L8.32 32.13a15.92 15.92 0 1022.21 22.22L60.3 12.83A7.47 7.47 0 0049.87 2.4z"></path></svg>
      WebXR
      <svg fill="none" viewBox="0 0 63 56" className="metaballix-sl1d3"><path fill="#FF8577" stroke="#000" strokeWidth="2" d="M1.7 1.8v52.5l18.7-9.6 11.3 9.5 11.2-9.5 18.8 9.5V1.8l-18.8 10-11.2-10-11.3 10-18.7-10z"></path></svg>
      Three.js
      <svg fill="none" viewBox="0 0 77 52" className="metaballix-sl1d3"><path fill="#FF8577" stroke="#000" strokeWidth="2" d="M1 51V26C1 9 9 1 22 1c17 0 25 17 37 17 9 0 13-4 13-17h4v25c0 17-8 25-21 25-16 0-25-17-37-17-8 0-13 5-13 17H1z"></path></svg>
      Solana
      <svg fill="none" viewBox="0 0 60 60" className="metaballix-sl1d3"><path fill="#FF8577" stroke="#000" strokeWidth="2" d="M23.93 32.75h-7.05a15.88 15.88 0 1115.87-15.88v7.05a3.33 3.33 0 003.33 3.33h7.05a15.88 15.88 0 11-15.88 15.88v-7.05a3.33 3.33 0 00-3.32-3.33z"></path></svg>
      Metaplex
      <svg fill="none" viewBox="0 0 67 50" className="metaballix-sl1d3"><mask id="a" fill="#fff"><path d="M12.5 0H0v50h12.5c8.7 0 16.35-4.44 20.83-11.18A24.98 24.98 0 0054.17 50h12.5V0h-12.5c-8.7 0-16.36 4.44-20.84 11.18A24.98 24.98 0 0012.5 0z"></path></mask><path fill="#FF8577" d="M12.5 0H0v50h12.5c8.7 0 16.35-4.44 20.83-11.18A24.98 24.98 0 0054.17 50h12.5V0h-12.5c-8.7 0-16.36 4.44-20.84 11.18A24.98 24.98 0 0012.5 0z"></path><path fill="#000" d="M0 0v-2h-2v2h2zm0 50h-2v2h2v-2zm33.33-11.18l1.67-1.1-1.67-2.5-1.66 2.5 1.66 1.1zM66.67 50v2h2v-2h-2zm0-50h2v-2h-2v2zm-12.5 0v-2 2zM33.33 11.18l-1.66 1.1 1.66 2.5 1.67-2.5-1.67-1.1zM0 2h12.5v-4H0v4zm2 48V0h-4v50h4zm10.5-2H0v4h12.5v-4zm19.17-10.28A22.98 22.98 0 0112.5 48v4c9.4 0 17.66-4.8 22.5-12.07l-3.33-2.21zM54.17 48c-8 0-15.05-4.08-19.17-10.28l-3.33 2.21A26.98 26.98 0 0054.17 52v-4zm12.5 0h-12.5v4h12.5v-4zm-2-48v50h4V0h-4zm-10.5 2h12.5v-4h-12.5v4zM35 12.28A22.98 22.98 0 0154.17 2v-4c-9.4 0-17.67 4.8-22.5 12.07L35 12.28zM12.5 2c8 0 15.04 4.08 19.17 10.28L35 10.07A26.98 26.98 0 0012.5-2v4z" mask="url(#a)"></path></svg>
      Arweave
      <svg fill="none" viewBox="0 0 63 62" className="metaballix-sl1d3"><path fill="#FF8577" stroke="#000" strokeWidth="2" d="M49.87 2.4L8.32 32.13a15.92 15.92 0 1022.21 22.22L60.3 12.83A7.47 7.47 0 0049.87 2.4z"></path></svg>
      Ankr
      <svg fill="none" viewBox="0 0 63 56" className="metaballix-sl1d3"><path fill="#FF8577" stroke="#000" strokeWidth="2" d="M1.7 1.8v52.5l18.7-9.6 11.3 9.5 11.2-9.5 18.8 9.5V1.8l-18.8 10-11.2-10-11.3 10-18.7-10z"></path></svg>  
    </div>
  </div>
{/* End of Figma Slider */}

<section id="footer" className="w-100 mb-0 pb-2">
<div className="container">
  <footer className="py-2">
    <div className="row footer-top justify-content-center justify-content-md-evenly pb-3 mx-auto">
{/* <div className="nomics-ticker-widget" data-name="Solana" data-base="SOL" data-quote="USDT"></div> */}

      <div className="col-5 col-sm-5 col-md-2 mb-3">
        <h4 className="text-info">Addresses</h4>
        <ul className="nav flex-column">
        <li className="nav-item"><a href="https://explorer.solana.com/address/E359HKTV192s4kpg4QXTmj7eQ6fzvsL2KbU9QJGDrM3e/" target="_blank" rel="noreferrer" className="nav-link px-2 text-light fw-light">$MBALL</a></li>
      <li className="nav-item"><a href="https://explorer.solana.com/address/APkf6LAn4uiSHWBFuXiaq6GaJQzNHNzcK5TSsxRUA3bh/" target="_blank" rel="noreferrer" className="nav-link px-2 text-light fw-light">$XONE</a></li>
      <li className="nav-item"><a href="https://explorer.solana.com/address/7gDoUkqmkjX8BEVrkYQs6cawFB4ronky8AfkCEyHbzB2/" target="_blank" rel="noreferrer"  className="nav-link px-2 text-light fw-light">$PLASMA</a></li>
      <li className="nav-item"><a href="https://explorer.solana.com/address/3v381LGCJbcNSV4PYwM6jRNM1Tt5LUhYrFyPUMMs6hE6/" target="_blank" rel="noreferrer"  className="nav-link px-2 text-light fw-light">Token Program</a></li>
      <li className="nav-item"><a href="https://explorer.solana.com/address/672nFe4KV9QdNFFTJxZAbYzyJZ1mb1YoXy5RjuPohzdq/" target="_blank" rel="noreferrer"  className="nav-link px-2 text-light fw-light">Mint Authority</a></li>
        </ul>
      </div>

      <div className="col-4 col-sm-4 col-md-2 mb-3">
      <h4 className="text-info">Ecosystem</h4>
        <ul className="nav flex-column">
        <li className="nav-item"><a href="/#nft" className="nav-link px-2 text-light fw-light">Metaballs</a></li>
        <li className="nav-item"><a href="/#xone" className="nav-link px-2 text-light fw-light">Xones</a></li>
        <li className="nav-item"><a href="/#plasmaverse" className="nav-link px-2 text-light fw-light">Plasmaverse</a></li>
        <li className="nav-item"><a href="/#tokenomics" className="nav-link px-2 text-light fw-light">Tokenomics</a></li>
        </ul>
      </div>

      <div className="col-3 col-sm-3 col-md-2 mb-3">
      <h4 className="text-info">Jump to</h4>
        <ul className="nav flex-column">
        <li className="nav-item"><a href="/#hero-minter" className="nav-link px-2 text-light fw-light">Mint</a></li>
        <li className="nav-item"><a href="/#team" className="nav-link px-2 text-light fw-light">Team</a></li>
        <li className="nav-item"><a href="/#roadmap" className="nav-link px-2 text-light fw-light">Roadmap</a></li>
        <li className="nav-item"><a href="/#faq" className="nav-link px-2 text-light fw-light">FAQs</a></li>
        </ul>
      </div>

    </div>
    <div className="footer-links d-flex justify-content-between my-0 py-4 pb-0 mb-0 px-1 px-md-3 px-lg-5">
      <p><small>© 2022 Metaballix Foundation</small></p>
      <ul className="list-unstyled d-flex">
      <li className="ms-3"><a className="text-light" href="https://twitter.com/metaballix/" target="_blank" rel="noreferrer"><i className="fa-brands fa-twitter-square"></i></a></li>
      <li className="ms-3"><a className="text-light" href="https://t.me/metaballix/" target="_blank" rel="noreferrer"><i className="fa-brands fa-telegram"></i></a></li>
      <li className="ms-3"><a className="text-light" href="https://metaballix.github.io/" target="_blank" rel="noreferrer"><i className="fa-brands fa-github-square"></i></a></li>
      <li className="ms-3"><a className="text-light" href="https://xone.land/" target="_blank" rel="noreferrer"><i className="fa-solid fa-vr-cardboard"></i></a></li>
    </ul>
    </div>
  </footer>
</div>
    {/* Scrolling img reels */}
    <div className="nft-hero py-0 my-0">
    <div className="nft-reel-1"></div>
    {/* <div className="nft-reel-2"></div> */}
    {/* <div className="nft-reel-3"></div> */}
    {/* <div className="nft-reel-4"></div> */}
    {/* <div className="nft-reel-7"></div> */}
    {/* <div className="nft-reel-6"></div> */}
    {/* <div className="nft-reel-5"></div> */}
    {/* 
    <div className="nft-hero-text">
    <h1 className="gradient-txt-green" style={{ fontSize: "2.8rem", letterSpacing: "0.1em" }}>19,326,120</h1>
      <h3>Tokenized DNA Compositions</h3>
      <a href="https://explorer.solana.com/address/E359HKTV192s4kpg4QXTmj7eQ6fzvsL2KbU9QJGDrM3e" target="_blank" rel="noreferrer">
        <button className="btn btn-lg btn-dark pd-3" type="button">
          <span style={{ letterSpacing: "0.05em", fontWeight: 500 }}>$MBALL ON </span>
          <img alt="Solana Explorer" src="https://cdn.glitch.global/37011ed6-4b84-44e8-8f53-630683dd483b/solana-explorer-logo-dark.svg?v=1646454031603" style={{ height:"1rem", marginTop: "-6px", paddingLeft: "5px" }} />
        </button></a>
    </div>
    */}
    </div>
    {/* End of img reels */}

</section>


</div>

  );
};

export default App;
